import SelectComponent from 'components/common/select/AsyncSelect';
import {getReleases} from 'containers/releases/api';
import {useEffect, useState} from 'react';

const parseReleases = releaseList => releaseList?.map(rel => ({...rel, label: rel.version, value: rel.id})) || [];
const getReleaseSuggestions = async (search = '') => {
  const results = await getReleases({search});
  return parseReleases(results?.releases);
};

function CurrentRelease({currentRelease, handleClose, onVersionChange}) {
  const [releases, setReleases] = useState([]);
  useEffect(() => {
    getReleases().then(result => {
      setReleases(parseReleases(result?.releases));
    });
  }, []);

  return (
    <div className="question-modal">
      <hr className="custom-modal-line mt-0 " />
      <div className="d-grid gap-3">
        <div>
          <label className="fw-bold">Current Release</label>
          <SelectComponent
            placeholder={'Select Version'}
            dataCallback={getReleaseSuggestions}
            options={releases}
            value={currentRelease}
            onChange={onVersionChange}
          />
        </div>

        <div>Version: {currentRelease?.version}</div>
        <div>Release Type: {currentRelease?.release_type}</div>
        <div>Release Date: {currentRelease?.created_at}</div>
        <div className="text-start">
          {!!currentRelease?.features_list.length && (
            <>
              <label>Features:</label>
              <ul className="list-unstyled">
                {currentRelease?.features_list?.map(feat => (
                  <li key={feat}>{feat}</li>
                ))}
              </ul>
            </>
          )}
          {!!currentRelease?.bugs_list?.length && (
            <>
              <label>Bugs:</label>
              <ul className="list-unstyled">
                {currentRelease?.bugs_list?.map(bug => (
                  <li key={bug}>{bug}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>

      {/* <hr className="custom-modal-line mt-4" />
      <div className="d-flex flex-row-reverse">
        <button className="secondary-btn w-100" type="button" onClick={handleClose}>
          Cancel
        </button>
      </div> */}
    </div>
  );
}

export default CurrentRelease;
