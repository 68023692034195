import {OUTREACH_LEADERS, BULK_DELETE, SEND_CONFIRMATION} from 'constants/endpoints';
import {result} from 'lodash';
import {deleteApi, get, post, put, patch} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getOutreachLeaders = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    active: obj.active || false,
  };
  try {
    const result = await get(
      `${OUTREACH_LEADERS}?page=${params.page}&per=${params.perPage}&query=${params.search}${
        params.active ? '&filter[status]=active' : ''
      }`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const createOutreachLeader = async data => {
  try {
    const result = await post(`${OUTREACH_LEADERS}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateOutreachLeader = async (data, id) => {
  try {
    const result = await put(`${OUTREACH_LEADERS}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateOutreachLeaderPassword = async (data, id) => {
  try {
    const result = await patch(`${OUTREACH_LEADERS}/${id}/password_update`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff's password updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteOutreachLeader = async id => {
  try {
    const result = await deleteApi(`${OUTREACH_LEADERS}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const deleteBulkOutreachLeader = async data => {
  try {
    const result = await put(`${OUTREACH_LEADERS}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const sendOutreachLeaderConfirmationEmail = async id => {
  try {
    const result = await post(`${OUTREACH_LEADERS}/${id}/${SEND_CONFIRMATION}`, null, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader confirmation sent successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
