import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Layout from 'components/layout';
import {getUserInfo, isSidebarVisible} from 'utils/common';
import {LOGIN_ROLES} from 'constants/common';

const Protected = ({isLoggedIn, showSurveyHeader, children}) => {
  const {pathname} = useLocation();
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <Layout
      visibleSidebar={isSidebarVisible(pathname)}
      role={getUserInfo()?.type}
      showSurvey={!isSidebarVisible(pathname)}
      showSurveyHeader={showSurveyHeader}
    >
      {children}
    </Layout>
  );
};

export default Protected;
