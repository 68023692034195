import React, {useState} from 'react';
import Pagination from './pagination';
import SearchInput from './search-input';
import Loader from './../loader';
import './index.scss';
import {Button} from 'react-bootstrap';
import CustomDropdown from '../customdropdown';
import UploadModal from '../uploadModal';
import {UploadSimple} from 'phosphor-react';
import {importFileUrl} from 'services/common';
const TableWrapper = ({
  searchPlaceholder,
  searchValue,
  setSearhQuery,
  actionButtons,
  selectedItems,
  currentPage,
  pageSize,
  totalListCount,
  onPageChange,
  children,
  isLoading,
  upload,
  uploadKey,

  // meta,
  // setMeta,
  // handleRefreshPage,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const handleShowUploadModal = () => {
    setShowUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };
  const handleUpload = async values => {
    const payload = {...values, key: uploadKey};
    try {
      setIsUploading(true);
      const result = await importFileUrl(payload);
    } catch (error) {
      console.log('error', error);
    } finally {
      handleCloseUploadModal();
      setIsUploading(false);
    }
  };
  return (
    <>
      <div className="table-main">
        <div className="table-head-content  ">
          <div className="search-otr">
            {selectedItems > 0 ? (
              <span className="selected-items-otr">{`${selectedItems} items selected`}</span>
            ) : (
              <SearchInput placeholder={searchPlaceholder} value={searchValue} handleChange={setSearhQuery} />
            )}
            <div className=" d-flex flex-row custom-dropdown-wrapper">
              {actionButtons
                .filter(item => item.dropdown)
                .map((item, index) => (
                  <CustomDropdown
                    key={index}
                    label={item.label}
                    options={item.dropdownOptions}
                    selectedOption={item.selectedFilter}
                    onSelectOption={item.handlefilterSelect}
                  />
                ))}
            </div>
            <div className="button-container d-flex flex-row">
              {/* {upload && (
                <Button
                  className={`my-button secondary-btn  me-sm-4 `}
                  onClick={() => handleShowUploadModal()}
                  style={{marginTop: '5px', marginRight: '5px'}}
                >
                  <UploadSimple size={24} className="me-3" />
                  Upload File
                </Button>
              )} */}
              {actionButtons?.length > 0 &&
                actionButtons
                  .filter(item => !item.dropdown)
                  .map((item, index) => {
                    return (
                      <Button
                        key={item.label + index}
                        className={`my-button ${item.classes} ${index === 0 ? 'me-sm-4' : 'me-0'}`}
                        onClick={item.handleClick}
                        style={{marginTop: '5px', marginRight: '5px'}}
                      >
                        {item?.icon && item.icon}
                        {item.label}
                      </Button>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="table-otr ">
          {isLoading ? (
            <div className="loader-wrapper">
              <Loader />
            </div>
          ) : (
            <>
              {searchValue && (
                <p className="search-result ">
                  {`Showing ${totalListCount} for `}
                  <span className="search-value">{searchValue}</span>
                </p>
              )}
              {children}
              <div className="footer-table">
                <div className="d-flex align-items-center">
                  <div className="text-otr mx-0 mt-0">
                    <p className="entity-text heading-xs">
                      {`Showing ${(currentPage - 1) * pageSize + 1} to ${
                        (currentPage - 1) * pageSize > totalListCount ? totalListCount : currentPage * pageSize
                      } of ${totalListCount} Entries`}
                    </p>
                  </div>
                </div>
                <Pagination
                  itemsCount={totalListCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {showUploadModal && (
        <UploadModal
          handleCloseModal={handleCloseUploadModal}
          handleSubmission={handleUpload}
          isLoading={isUploading}
        />
      )}
    </>
  );
};

export default TableWrapper;
