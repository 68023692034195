import CustomModal from 'components/common/modal';
import TableWrapper from 'components/common/table-wrapper';
import Tooltip from 'components/common/tooltip';
import AddEditReleaseForm from 'components/release/AddEditReleaseForm';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import {Pencil, Plus} from 'phosphor-react';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {fetchLatestRelease} from 'store/reducers/latestRelease';
import {capitalizeName, highlightFirstMatch, truncateString} from 'utils/common';
import {createRelease, getReleases, updateRelease} from './api';

let timeout;

const Releases = () => {
  const dispatch = useDispatch();
  const [releaseList, setReleaseList] = useState([]);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [releaseToEdit, setReleaseToEdit] = useState(undefined);

  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };

  const handleSetSearchQuery = value => {
    let newMetaObj = {...meta, search: value};
    setMeta(newMetaObj);
    debounceFn(() => {
      fetchReleaseList(newMetaObj);
    }, DEBOUNCE_DELAY);
  };

  const handlePageChange = value => {
    let newMetaObj = {...meta, page: value};
    setMeta(newMetaObj);
    debounceFn(() => {
      fetchReleaseList(newMetaObj);
    }, DEBOUNCE_DELAY);
  };

  const fetchReleaseList = async (metaObj = meta) => {
    setLoading(true);
    try {
      const result = await getReleases(metaObj);
      if (result?.releases) {
        setReleaseList(result?.releases);
        setMeta(prev => ({
          ...prev,
          perPage: result?.meta?.per_page,
          totalCount: result?.meta?.total_count,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReleaseModal = () => {
    setIsModalVisible(prev => !prev);
  };

  const handleRelease = async payload => {
    let {id, ...data} = payload;
    const result = await (!!id ? updateRelease(id, data) : createRelease(data));
    if (result?.meta?.status === 200) {
      handleReleaseModal();
      fetchReleaseList();
      dispatch(fetchLatestRelease());
    }
  };

  useEffect(() => {
    fetchReleaseList();
  }, []);

  return (
    <>
      <div className="main-contents">
        <h1>Releases</h1>
        <TableWrapper
          searchPlaceholder="Search Release Number"
          setSearhQuery={handleSetSearchQuery}
          searchValue={meta?.search}
          totalListCount={meta?.totalCount || 0}
          pageSize={meta?.perPage}
          currentPage={meta?.page}
          onPageChange={handlePageChange}
          isLoading={loading}
          actionButtons={[
            {
              label: 'Add Release',
              classes: 'primary-btn',
              icon: <Plus size={24} className="me-3" />,
              handleClick: handleReleaseModal,
            },
          ]}
        >
          <div className="container-fluid">
            <div className="table-responsive">
              <table className="table table-rows">
                <thead className="table-header">
                  <tr>
                    <th>Version</th>
                    <th>Type</th>
                    <th>Date Created</th>
                    <th>Features</th>
                    <th>Bugs</th>
                  </tr>
                </thead>
                <tbody>
                  {releaseList?.map((release, index) => (
                    <tr key={index}>
                      <td>{highlightFirstMatch(capitalizeName(release.version), meta.search)}</td>
                      <td>{highlightFirstMatch(release.release_type, meta.search)}</td>
                      <td>{release.created_at}</td>
                      <td>
                        <Tooltip
                          id="Features"
                          title={
                            <ul className="text-start">
                              {release.features_list?.map(feat => (
                                <li key={feat}>{feat}</li>
                              ))}
                            </ul>
                          }
                        >
                          <span>{truncateString(release?.features)}</span>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          id="Bugs"
                          title={
                            <ul className="text-start">
                              {release.bugs_list?.map(bug => (
                                <li key={bug}>{bug}</li>
                              ))}
                            </ul>
                          }
                        >
                          <span>{truncateString(release?.bugs)}</span>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title="Edit">
                          <Pencil
                            size={24}
                            className="opacity-50"
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              setReleaseToEdit(release);
                              handleReleaseModal();
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </TableWrapper>
        {isModalVisible && (
          <CustomModal size="sm" show onHide={handleReleaseModal} heading={`${releaseToEdit ? 'Edit' : 'Add'} Release`}>
            <AddEditReleaseForm
              handleSubmit={handleRelease}
              handleClose={handleReleaseModal}
              releaseToEdit={releaseToEdit}
            />
          </CustomModal>
        )}
      </div>
    </>
  );
};

export default Releases;
