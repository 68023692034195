import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage, Formik} from 'formik';
import {CaretDown, Plus, DotsSixVertical, TextAlignCenter, Trash, UserGear, PhoneCall} from 'phosphor-react';
import MultichoiceIcon from 'assets/images/multichoice.svg';
import React from 'react';
import ToggleButton from 'components/common/togglebutton';
import {questionsInitialValues as initialValues, questionsSchema as schema} from 'constants/questions';
import Tooltip from 'components/common/tooltip';
import {ReactComponent as AlertInfoImg} from 'assets/images/alert-info.svg';
import {CalendarBlank} from 'phosphor-react';
import {closestCenter, DndContext} from '@dnd-kit/core';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {restrictToParentElement, restrictToWindowEdges} from '@dnd-kit/modifiers';
const QuestionForm = ({handleQuestionSubmittion, handleClose, currentQuestionEdit, questionsList}) => {
  const onDragEnd = (event, setFieldValue, options) => {
    const {active, over} = event;

    if (active && over && active.id === over.id) {
      return;
    }
    const oldIndex = options.findIndex(user => user.id === active.id);
    const newIndex = options.findIndex(user => user.id === over.id);
    const newArray = arrayMove(options, oldIndex, newIndex);
    setFieldValue('question_options', newArray);
  };
  return (
    <Formik
      initialValues={
        currentQuestionEdit
          ? ['text', 'dynamic', 'date', 'phone'].includes(currentQuestionEdit.question_type)
            ? {...currentQuestionEdit, question_options: [{value: '', next_question_id: ''}]}
            : currentQuestionEdit
          : initialValues
      }
      validationSchema={schema}
      onSubmit={(values, {setSubmitting}) => {
        if (['text', 'dynamic', 'date', 'phone'].includes(values.question_type)) {
          handleQuestionSubmittion({...values, question_options: []}, setSubmitting);
        } else {
          handleQuestionSubmittion(
            {
              ...values,
              question_options: values.question_options.map((el, i) => {
                return {...el, order: i + 1};
              }),
            },
            setSubmitting,
          );
        }
      }}
      enableReinitialize
    >
      {({handleChange, values, handleSubmit, setFieldValue, errors, isSubmitting}) => {
        return (
          <div className="question-modal">
            {console.log('errors', errors)}
            <form className="form-main" onSubmit={handleSubmit}>
              <hr className="custom-modal-line mt-0 " />

              <div className="">
                <Input
                  handleChange={handleChange}
                  placeholder="e.g. What is the status of patient?"
                  type="text"
                  label="Question"
                  value={values.description}
                  name="description"
                  // disabled={currentQuestionEdit ? true : false}
                  error={<ErrorMessage className="error-message" component="p" name="description" />}
                />
              </div>
              <div className="mb-2 ">
                <SelectComponent
                  label="Answer Type"
                  options={[
                    {
                      value: 'text',
                      icons: <TextAlignCenter className="opacity-50" size={14} />,
                      label: <span style={{marginLeft: '10px'}}>Text</span>,
                    },
                    {
                      value: 'phone',
                      icons: <PhoneCall className="opacity-50" size={14} />,
                      label: <span style={{marginLeft: '10px'}}>Phone</span>,
                    },
                    {
                      value: 'multiple_choice',
                      icons: <img src={MultichoiceIcon} alt="icons" />,
                      label: <span style={{marginLeft: '12px'}}>Multiple Choice</span>,
                    },
                    {
                      value: 'dropdown',
                      icons: <CaretDown className="opacity-50" size={16} />,
                      label: <span style={{marginLeft: '10px'}}>Dropdown</span>,
                    },
                    {
                      value: 'multi_select',
                      icons: <img src={MultichoiceIcon} alt="icons" />,
                      label: <span style={{marginLeft: '12px'}}>Multi Select</span>,
                    },
                    {
                      value: 'date',
                      icons: <CalendarBlank size={24} className="opacity-50 " />,
                      label: <span style={{marginLeft: '12px'}}>Date</span>,
                    },
                    {
                      value: 'dynamic',
                      icons: <UserGear size={24} className="opacity-50 " />,
                      label: <span style={{marginLeft: '12px'}}>LCM Supervisor selection</span>,
                    },
                  ]}
                  selectedValue={values.question_type}
                  placeholder="Select Answer Type "
                  handleChange={obj => {
                    obj ? setFieldValue('question_type', obj.value) : setFieldValue('question_type', obj);
                  }}
                  // disabled={currentQuestionEdit ? true : false}
                  error={<ErrorMessage className="error-message" component="p" name="question_type" />}
                />
                {values.question_type == 'dynamic' && (
                  <p className="text-gray small">
                    <AlertInfoImg />
                    <span className="small">
                      {' '}
                      Type <span className="text-danger">LCM Supervisor selection</span> will fetch the list of all Lead
                      Care Manager Supervisors Dynamically.
                    </span>
                  </p>
                )}
              </div>
              <ToggleButton
                label="Is this question the first question of the survey?"
                handleChange={(name, value) => {
                  setFieldValue(name, !!value ? true : false);
                }}
                value={values.is_first_question ? 1 : 0}
                name="is_first_question"
                // disabled={currentQuestionEdit ? true : false}
              />
              {['text', 'dynamic', 'date', 'phone'].includes(values.question_type) && (
                <div className="mb-4 pb-1">
                  <SelectComponent
                    label="Link next question to this question"
                    options={questionsList}
                    selectedValue={values.next_question_id}
                    placeholder="Select Question"
                    handleChange={obj => {
                      try {
                        setFieldValue(`next_question_id`, obj ? obj.value : obj);
                      } catch (error) {
                        console.error('Error setting field value:', error);
                      }
                    }}
                    isClearable={true}
                    // disabled={currentQuestionEdit ? true : false}
                    error={<ErrorMessage className="error-message" component="p" name={`next_question_id`} />}
                  />
                </div>
              )}
              <div>
                {/* {values.question_type &&
                  !['text', 'dynamic', 'date', 'phone'].includes(values.question_type) &&
                  values.question_options
                    // ?.filter(item => item._destroy !== true)
                    .map((item, index) => {
                      const optionId = item.id;
                      return item._destroy !== true ? (
                        <div key={optionId}>
                          {index === 0 && (
                            <p className="heading-xsb mt-4 mb-0">{!currentQuestionEdit ? 'Add ' : ''}Option</p>
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="container">
                              <div className="  row ">
                                <div className="col-1 mt-3 pt-1">
                                  {' '}
                                  <span>{`${index + 1}. `}</span>{' '}
                                </div>
                                <div className="col-10 p-0">
                                  <Input
                                    value={item.value}
                                    placeholder="place value"
                                    type="text"
                                    handleChange={handleChange}
                                    name={`question_options[${index}].value`}
                                    className={'p-0 m-0'}
                                    // disabled={currentQuestionEdit ? true : false}
                                    error={
                                      <ErrorMessage
                                        className="error-message"
                                        component="p"
                                        name={`question_options[${index}].value`}
                                      />
                                    }
                                  />
                                </div>
                                <div className="col-1 mt-2 pt-2">
                                  {
                                    <Tooltip title="delete">
                                      <Trash
                                        size={24}
                                        onClick={() => {
                                          setFieldValue(
                                            'question_options',
                                            values.question_options.map((item, itemIndex) => {
                                              return optionId
                                                ? item.id === optionId
                                                  ? {...item, _destroy: true}
                                                  : item
                                                : itemIndex === index
                                                ? {...item, _destroy: true}
                                                : item;
                                            }),
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  }
                                </div>
                                <div className="col-1"></div>
                                <div className="col-10 p-0 mb-4 mt-3 text-nowrap">
                                  <SelectComponent
                                    label="Link next question to above option"
                                    options={questionsList}
                                    selectedValue={item.next_question_id}
                                    placeholder="Select Question "
                                    handleChange={obj => {
                                      try {
                                        setFieldValue(
                                          `question_options[${index}].next_question_id`,
                                          obj ? obj.value : obj,
                                        );
                                      } catch (error) {
                                        console.error('Error setting field value:', error);
                                      }
                                    }}
                                    isClearable={true}
                                    // disabled={currentQuestionEdit ? true : false}
                                    className={'p-0 m-0'}
                                    error={
                                      <ErrorMessage
                                        className="error-message"
                                        component="p"
                                        name={`question_options[${index}].next_question_id`}
                                      />
                                    }
                                  />
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null;
                    })} */}
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={e =>
                    onDragEnd(
                      e,
                      setFieldValue,
                      values.question_type &&
                        !['text', 'dynamic', 'date', 'phone'].includes(values.question_type) &&
                        values.question_options,
                    )
                  }
                  modifiers={[restrictToParentElement]}
                >
                  <SortableContext items={values.question_options} strategy={verticalListSortingStrategy}>
                    {values.question_type &&
                      !['text', 'dynamic', 'date', 'phone'].includes(values.question_type) &&
                      values.question_options
                        // ?.filter(item => item._destroy !== true)
                        .map((item, index) => {
                          const optionId = item.id;
                          return item._destroy !== true ? (
                            <>
                              {index === 0 && (
                                <p className="heading-xsb mt-4 mb-0">{!currentQuestionEdit ? 'Add ' : ''}Option</p>
                              )}

                              <SortableOption
                                key={optionId}
                                {...{
                                  index,
                                  item,
                                  handleChange,
                                  setFieldValue,
                                  currentQuestionEdit,
                                  values,
                                  optionId,
                                  questionsList,
                                }}
                              />
                            </>
                          ) : null;
                        })}
                  </SortableContext>
                </DndContext>
                {values.question_type && !['text', 'dynamic', 'date', 'phone'].includes(values.question_type) && (
                  <button
                    className="secondary-btn mt-3"
                    type="butotn"
                    onClick={() => {
                      setFieldValue('question_options', [
                        ...values.question_options,
                        {value: '', next_question_id: ''},
                      ]);
                    }}
                  >
                    {<Plus size={18} className="me-3" />}Add Option
                  </button>
                )}
              </div>
              <ToggleButton
                label="Question Status"
                handleChange={(name, value) => {
                  setFieldValue(name, !!value ? 'active' : 'inactive');
                }}
                value={values.status === 'active' ? 1 : 0}
                name="status"
                // disabled={currentQuestionEdit ? true : false}
              />

              <ToggleButton
                label="Display on Dashboard"
                handleChange={(name, value) => {
                  setFieldValue(name, !!value ? true : false);
                }}
                value={values.dashboard_display ? 1 : 0}
                name="dashboard_display"
                // disabled={currentQuestionEdit ? true : false}
              />

              {
                <>
                  <hr className="custom-modal-line mt-4 " />

                  <div className="action  gap-2">
                    <button
                      className="secondary-btn w-50"
                      type="button"
                      onClick={handleClose}
                      // disabled={currentQuestionEdit ? true : false}
                    >
                      Cancel
                    </button>
                    {isSubmitting ? (
                      <>
                        <span className="primary-btn w-50">
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          Submitting...
                        </span>
                      </>
                    ) : (
                      <input
                        className="primary-btn w-50"
                        type="submit"
                        name="submit"
                        value={`${currentQuestionEdit ? 'Save Changes' : 'Add Question'}`}
                        // disabled={currentQuestionEdit ? true : false}
                      />
                    )}
                  </div>
                </>
              }
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default QuestionForm;
const SortableOption = ({
  index,
  item,
  handleChange,
  setFieldValue,
  currentQuestionEdit,
  values,
  optionId,
  questionsList,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: optionId});
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="container m-0 p-0">
        <div className="  row p-0 m-0">
          <div className="col-md-1 p-0  d-flex align-items-center justify-content-start ">
            <DotsSixVertical size={32} {...attributes} {...listeners} />
          </div>
          <div className="col-md-4 p-0 mx-auto d-flex align-items-center w-imp-100">
            <span
              className=" pe-2  fw-medium"
              style={{
                whiteSpace: 'nowrap',
              }}
            >{`${index + 1}. `}</span>{' '}
            <Input
              value={item.value}
              placeholder="place value"
              type="text"
              handleChange={handleChange}
              name={`question_options[${index}].value`}
              className={'p-0 m-0 w-imp-100'}
              // disabled={currentQuestionEdit ? true : false}
              error={<ErrorMessage className="error-message" component="p" name={`question_options[${index}].value`} />}
            />
          </div>

          <div className="col-md-5 p-0 mx-auto d-flex align-items-center">
            <span
              className=" pe-2  fw-medium"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Go to
            </span>
            <div className="w-100">
              <SelectComponent
                options={questionsList}
                selectedValue={item.next_question_id}
                placeholder="Select Question "
                handleChange={obj => {
                  try {
                    setFieldValue(`question_options[${index}].next_question_id`, obj ? obj.value : obj);
                  } catch (error) {
                    console.error('Error setting field value:', error);
                  }
                }}
                isClearable={true}
                // disabled={currentQuestionEdit ? true : false}
                className={'p-0 m-0'}
                error={
                  <ErrorMessage
                    className="error-message"
                    component="p"
                    name={`question_options[${index}].next_question_id`}
                  />
                }
              />
            </div>
          </div>

          <div className="col-md-1 mt-2 pt-2 mx-auto d-flex align-items-center justify-content-end">
            {
              <Tooltip title="delete">
                <Trash
                  size={24}
                  onClick={() => {
                    setFieldValue(
                      'question_options',
                      values.question_options.map((item, itemIndex) => {
                        return optionId
                          ? item.id === optionId
                            ? {...item, _destroy: true}
                            : item
                          : itemIndex === index
                          ? {...item, _destroy: true}
                          : item;
                      }),
                    );
                  }}
                  className="cursor-pointer"
                />
              </Tooltip>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
