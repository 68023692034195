import React, {useEffect, useState} from 'react';
// import ProgressBar from './ProgressBar';
import './survey.scss';
import QuestionCard from './QuestionCard';
import QuestionsOverview from './QuestionsOverview';
import ThanksNote from './ThanksNote';
import {submitLeadCareManager, submitQuestion} from 'containers/survey/api';
import { toast } from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import { alertTypes } from 'constants/common';
import {question_type} from 'constants/common';
const SurveyComponent = ({
  surveyQuestions,
  id,
  surveyId,
  outreachLeadersList,
  selectedOutreachLeader,
  outreachLeadersLength,
  lcmLoading,
  surveyLoading,
  surveyStartedAt,
}) => {
  const [questions, setQuestions] = useState(surveyQuestions);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionOverview, setQuestionsOverview] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState('');
  const [thanksNote, setThanksNote] = useState(false);
  const [stack, setStack] = useState([0]);
  const [isSavingAns, setIsSavingAns] = useState(false);

  const compulsoryQuestion = answer_options => {
    return {
      id: surveyId,
      answer_type: question_type.dropdown,
      question_text: 'What is the name of LCM Supervisor?',
      answer_options,
    };
  };
  const setFirstQuestion = () => {
    const index = surveyQuestions.findIndex(question => question.is_first_question);
    if (index > 0) {
      setCurrentQuestion(index);
      setStack([index]);
    }
  };
  useEffect(() => {
    // setQuestions([...surveyQuestions, compulsoryQuestion(outreachLeadersList)]);
    setQuestions([
      ...surveyQuestions.map(el => {
        if (el.answer_type === 'dynamic') {
          return {...el, answer_options: outreachLeadersList};
        } else {
          return el;
        }
      }),
    ]);

    setQuestionsAnswers([
      ...surveyQuestions.map(item => {
        // if (item.answer_type === 'dynamic') {
        //   return {
        //     id: item.id || '',
        //     question_text:item.question_text|| 'What is the name of LCM Supervisor?',
        //     answer_text: selectedOutreachLeader?.name || item.answer_text || '',
        //     selected_answer_options: [
        //       {answer_option_id: selectedOutreachLeader?.id || '', value: selectedOutreachLeader?.name || ''},
        //     ],
        //   };
        // } else {
        return {
          id: item.id,
          question_text: item.question_text,
          answer_text: item.answer_text,
          next_question_id: item.next_question_id,
          selected_answer_options: item.selected_answer_options?.map(ele => ({
            answer_option_id: ele?.answer_option_id,
            value: ele.value,
            next_question_id: ele.next_question_id,
            order: ele.order,
          })),
        };
        // }
      }),
    ]);
    setFirstQuestion();
  }, [surveyQuestions, outreachLeadersLength]);

  const handleNext = async () => {
    // if (currentQuestion === questions?.length - 1) {
    //   if (questionsAnswers[currentQuestion]?.selected_answer_options?.length) {
    //     const result = await submitLeadCareManager(
    //       questions[currentQuestion].id,
    //       questionsAnswers[currentQuestion].selected_answer_options[0].answer_option_id,
    //     );

    //     if (result) {
    //       setQuestionsOverview(true);
    //     }
    //   }
    // } else {
    if (questionsAnswers[currentQuestion]?.selected_answer_options?.length) {

      // if (currentQuestion < questions?.length - 1 && result) {
        const selectedOptionId = questionsAnswers[currentQuestion]?.selected_answer_options[0].answer_option_id;
        const selectedOptionObj = questions[currentQuestion]?.answer_options?.find(
          el => el.id == selectedOptionId || el.question_option_id == selectedOptionId,
        );
        const nextQuestionId =
          questionsAnswers[currentQuestion].next_question_id || selectedOptionObj?.next_question_id;

        const nextQuestionIndex = questions.findIndex(q => q.question_id == nextQuestionId);
        if (nextQuestionId && nextQuestionIndex > -1) {
          setStack(pre => [...pre, nextQuestionIndex]);
          setCurrentQuestion(nextQuestionIndex);
        } else {
          setQuestionsOverview(true);
        }
      
      try{
      await submitQuestion(
        questions[currentQuestion].id,
        questionsAnswers[currentQuestion].selected_answer_options,
      );
      }catch(error){
        toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
        console.log(error);
      }  
    }
    // else {
    //   if (currentQuestion < questions?.length - 1) setCurrentQuestion(pre => pre + 1);
    // }
    // }
  };
  const handlePrevious = () => {
    const stackCopy = [...stack];
    stackCopy.pop();
    const lastVisited = stackCopy.pop();
    if (stack.length > 1) {
      setCurrentQuestion(lastVisited);
      setStack([...stackCopy, lastVisited]);
    }
  };
  return (
    <div className="survey">
      {thanksNote ? (
        <ThanksNote />
      ) : (
        <>
          {questionOverview ? (
            <QuestionsOverview
              questionsAnswers={questionsAnswers}
              setCurrentQuestion={setCurrentQuestion}
              setQuestionsOverview={setQuestionsOverview}
              setThanksNote={setThanksNote}
              surveyId={surveyId}
              setStack={setStack}
              stack={stack}
              // id={id}
            />
          ) : (
            <>
              {/* <ProgressBar
                progress={questions?.length ? (100 / Number(questions.length)) * (currentQuestion + 1) : 0}
              /> */}
              <QuestionCard
                questions={questions}
                selectedQuestion={currentQuestion}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setQuestionsOverview={setQuestionsOverview}
                setQuestionsAnswers={setQuestionsAnswers}
                questionsAnswers={questionsAnswers}
                stack={stack}
                lcmLoading={lcmLoading}
                surveyLoading={surveyLoading}
                isSavingAns={isSavingAns}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SurveyComponent;
