import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getLatestRelease} from 'containers/releases/api';

const initialState = {latestRelease: null};

export const fetchLatestRelease = createAsyncThunk('release/fetchLatest', async () => {
  const response = await getLatestRelease();
  return response.release;
});

const releaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLatestRelease.fulfilled, (state, action) => {
      state.latestRelease = action.payload;
    });
  },
});

export default releaseSlice.reducer;
