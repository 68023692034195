import React, {useCallback, useEffect, useState} from 'react';
// import './questionlist.scss';
import {AppWindow, Plus, Trash, Pencil, TrashSimple, UploadSimple} from 'phosphor-react';
import TableWrapper from 'components/common/table-wrapper';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import CustomModal from 'components/common/modal';
import QuestionForm from './question-form';
import {
  createQuestion,
  deleteQuestion,
  getQuestions,
  updateQuestions,
  deleteBulkQuestion,
} from 'containers/questions/api';
import {formatDate, highlightFirstMatch} from 'utils/common';
import Tooltip from 'components/common/tooltip';
let timeout;
export const question_type_labels = {
  dropdown: 'Dropdown',
  multiple_choice: 'Multiple Choice',
  input: 'Text',
  checkbox: 'Checkbox',
  text: 'Text',
  multi_select: 'Multi Select',
  dynamic: 'LCM Selection',
  date: 'Date',
};
const Questionslist = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [allQuestionsList, setAllQuestionsList] = useState([]);

  const [meta, setMeta] = useState(initialMetaForTable);
  const [loading, setLoading] = useState(true);
  const [selectedQuestions, setSelectedQuestions] = useState(0);
  const [isQuestionsModalVisible, setIsQuestionModalvisible] = useState(false);
  const [currentQuestionEdit, setCurrentQuestionEdit] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };

  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSelectAll = () => {
    const updatedCheckboxes = questionsList.map(checkbox => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setQuestionsList(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = index => {
    setQuestionsList(pre =>
      pre.map((item, itemIndex) => (itemIndex === index ? {...item, checked: !item.checked} : item)),
    );
  };
  const handleCloseQuestionModal = () => {
    setIsQuestionModalvisible(false);
    setCurrentQuestionEdit('');
  };
  const handleOpenQuestionModal = () => {
    setIsQuestionModalvisible(true);
  };
  const handleQuestionSubmittion = async (question, isSubmitting) => {
    console.log('question', question);
    const {
      id,
      description,
      question_type,
      status,
      is_first_question,
      next_question_id,
      question_options,
      dashboard_display,
    } = question;
    const questionOptionsCopy = question_options.map(option => ({...option}));

    const payload = {
      description,
      question_type,
      question_options_attributes: questionOptionsCopy,
      status,
      is_first_question,
      next_question_id,
      dashboard_display: !!dashboard_display,
    };

    // const payload = {
    //   description,
    //   question_type,
    //   question_options_attributes: [...question_options],
    //   status,
    // };

    try {
      if (typeof currentQuestionEdit === 'object') {
        const result = await updateQuestions(payload, id);
        if (result) {
          handleCloseQuestionModal();
          handleRefreshPage();
        }
      } else {
        const result = await createQuestion(payload);
        if (result) {
          handleCloseQuestionModal();
          handleRefreshPage();
        }
      }
    } catch (error) {
    } finally {
      isSubmitting(false);
    }
  };
  const handleDeleteQuestion = async id => {
    const result = await deleteQuestion(id);
    if (result) handleRefreshPage();
  };
  const handleBulkDeleteQuestion = async () => {
    const selectedQuestionIds = questionsList?.filter(question => question.checked).map(question => question.id);
    const payload = {
      ids: selectedQuestionIds,
    };
    const result = await deleteBulkQuestion(payload);
    if (result) handleRefreshPage();
  };
  const fetchQuestions = useCallback(async () => {
    setLoading(true);
    const result = await getQuestions(meta);

    if (result?.questions) {
      const data = result?.questions.map(item => ({...item, checked: selectAll}));
      setQuestionsList(data);
      setTotalCount(result?.meta.total_count);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  const fetchAllQuestions = useCallback(async () => {
    const result = await getQuestions({page: 1, perPage: 100, search: ''});

    if (result?.questions) {
      const data = result?.questions.filter(question => question.status === 'active');
      setAllQuestionsList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    fetchQuestions();
    fetchAllQuestions();
  }, [fetchQuestions, fetchAllQuestions]);
  useEffect(() => {
    setSelectedQuestions(
      selectAll
        ? questionsList.length
        : questionsList.reduce((accumulator, item) => {
            if (item.checked) {
              return accumulator + 1;
            } else return accumulator;
          }, 0),
    );
  }, [questionsList, selectAll]);

  return (
    <>
      <TableWrapper
        searchPlaceholder="Search Questions"
        setSearhQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        isLoading={loading}
        onPageChange={handlePageChange}
        selectedItems={selectedQuestions}
        upload={true}
        uploadKey={'Questions'}
        actionButtons={
          selectedQuestions > 0
            ? [
                // {
                //   label: `Delete ${selectedQuestions} Items`,
                //   classes: 'danger-btn m-0-imp',
                //   icon: <TrashSimple size={24} className="me-3" />,
                //   handleClick: handleBulkDeleteQuestion,
                // },
              ]
            : [
                {
                  label: 'Add Questions',
                  classes: 'primary-btn',
                  icon: <Plus size={24} className="me-3" />,
                  handleClick: () => {
                    handleOpenQuestionModal();
                  },
                },
              ]
        }
      >
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-rows">
              <thead className="table-header">
                <tr>
                  <th>
                    <div className="form-check ps-3 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th>Question</th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th>Date Update</th>
                </tr>
              </thead>
              <tbody>
                {questionsList?.map((question, index) => (
                  <tr key={index}>
                    <td>
                      <div className="form-check ps-3 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={question.checked}
                          onChange={event => handleCheckboxChange(index, event)}
                          value=""
                        />
                      </div>
                    </td>
                    <td className="fw-bold">{highlightFirstMatch(question.description, meta.search)}</td>
                    <td>{question_type_labels[question.question_type]}</td>
                    <td>
                      {
                        <div className={`status ${question.is_first_question ? 'Enrolled' : 'pending'} }`}>
                          {question.is_first_question ? 'First' : 'Dependent'}
                        </div>
                      }
                    </td>
                    <td>
                      <div className={`status ${question.status === 'inactive' ? 'inactive' : 'active'}`}>
                        {question.status === 'inactive' ? 'Inactive' : 'Active'}
                      </div>
                    </td>
                    <td>{question.created_at}</td>
                    <td>{question.updated_at}</td>
                    <td>
                      <Tooltip title="Edit">
                        <Pencil
                          size={24}
                          className="opacity-50 me-5"
                          onClick={() => {
                            setCurrentQuestionEdit(question);
                            handleOpenQuestionModal();
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      </Tooltip>
                      {/* <Tooltip title="delete">
                        <Trash
                          size={24}
                          className="ms-3 opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            question?.id && handleDeleteQuestion(question.id);
                          }}
                        />
                      </Tooltip> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </TableWrapper>
      {isQuestionsModalVisible && (
        <CustomModal
          size="lg"
          show
          onHide={handleCloseQuestionModal}
          heading={`${currentQuestionEdit ? 'Edit' : 'Add'} Question`}
        >
          <QuestionForm
            handleQuestionSubmittion={handleQuestionSubmittion}
            handleClose={handleCloseQuestionModal}
            currentQuestionEdit={
              currentQuestionEdit
                ? {
                    ...currentQuestionEdit,
                    question_options: currentQuestionEdit?.question_options
                      .map(el => ({
                        ...el,
                        next_question_id: el?.next_question?.id,
                      }))
                      .sort((a, b) => a?.order - b?.order),
                  }
                : currentQuestionEdit
            }
            questionsList={allQuestionsList
              .filter(q => q.id !== currentQuestionEdit?.id)
              .map(q => ({value: q.id, label: q.description}))}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Questionslist;
