import {CLIENT_ID, CLIENT_SECRET} from 'constants/common';
import {AUTH_ENDPOINT, FORGOT_PASSWORD_ENDPOINT, REVOKE_ENDPOINT} from 'constants/endpoints';
import {post, put} from 'services/network';
import {getAccessToken} from 'utils/common';
import { toast } from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import { alertTypes } from 'constants/common';

export const getAuthToken = ({email, password, refreshToken}, GRANT_TYPE) => {
  const data = {
    grant_type: GRANT_TYPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    email,
    ...(refreshToken && {refresh_token: refreshToken}),
    ...(password && {password: password}),
  };

  return post(AUTH_ENDPOINT, data);
};

export const forgetUserPassword = async (values, setErrors) => {
  try {
    const response = await post(FORGOT_PASSWORD_ENDPOINT, {user: values}, true);
    console.log('forgot password');
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    console.log(error);
    console.error(error);
  }
};

export const resetUserPassword = async (values, setErrors) => {
  try {
    const response = await put(FORGOT_PASSWORD_ENDPOINT, {user: values}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Password reset successfull" />);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
    }
    console.log(error);
    console.error(error);
  }
};
export const revokeAuthToken = () => {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    token: getAccessToken(),
  };

  return post(REVOKE_ENDPOINT, data);
};
