import React from 'react';

function AnswersStats({answerStats}) {
  const create2DList = list =>
    list?.reduce((result, value, index, arr) => {
      const obj = {...value, key: `Question No.${index + 1} of ${arr.length}`};
      if (index % 2 === 0) {
        result.push([obj]);
      } else {
        result[result.length - 1].push(obj);
      }

      return result;
    }, []);
  const renderAnswersStats = () => {
    const adjacencyList = create2DList(answerStats);
    return adjacencyList.map(listItem => (
      <>
        <div className="d-flex flex-column justify-content-between flex-xl-row flex-xxl-row">
          {listItem?.map(({question, answers, key}) => {
            return (
              <div className="d-flex flex-column col-md-6 px-3">
                <p className="opacity-50 mt-3 mt-xl-0 ">{key}</p>
                <h4>{question}</h4>
                {answers?.map(({percentage, value}, index) => (
                  <div
                    className={`progress progress-width d-flex justify-content-between ${
                      index === 0 ? '' : 'mt-3'
                    } position-relative`}
                  >
                    <div className="progress-bar" role="progressbar" style={{width: `${percentage}%`}}></div>
                    <div className=" position-absolute start-0 top-50 translate-middle-y ps-3 fw-bold">{value}</div>
                    <div className="progress-percent position-absolute end-0 top-50 translate-middle-y">
                      {percentage > 100 ? 100 : percentage}%
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <hr />
      </>
    ));
  };
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title p-3">Survey Results</h3>
            <hr />
            <div className="card-body p-3">
              <div className="container-fluid">{renderAnswersStats()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnswersStats;
