import {FILE_IMPORT, UPLOAD_URL} from 'constants/endpoints';
import {post} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const importFileUrl = async data => {
  try {
    const result = await post(`${FILE_IMPORT}/${UPLOAD_URL}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Submitted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
