import CustomModal from 'components/common/modal';
import TableWrapper from 'components/common/table-wrapper';
import Tooltip from 'components/common/tooltip';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import {getOutreachStaffs} from 'containers/outreachstaff/api';
import {
  createPolicy,
  deleteBulkPoliciess,
  deletePolicy,
  getHealthPlans,
  getPolicies,
  getPolicyHistoy,
  getStates,
  updatePolicy,
} from 'containers/policies/api';
import debounce from 'lodash/debounce';
import {Pencil, Plus, Scroll, Trash, TrashSimple} from 'phosphor-react';
import {useCallback, useEffect, useState} from 'react';
import {capitalizeName, highlightFirstMatch} from 'utils/common';
import HistoryModal from './HistoryModal';
import PoliciesModal from './PoliciesModal';

let timeout;

const Policies = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [policiesList, setPoliciesList] = useState([]);
  const [healthPlansList, setHealthPlansList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [outreachStaffList, setOutreachStaffList] = useState([]);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [selectedPolicies, setSelectedPolicies] = useState(0);
  const [isPoliciesModalVisible, setIsPoliciesModalvisible] = useState(false);
  const [isHistoryModalVisible, setIsHistoryModalvisible] = useState(false);

  const [currentPoliciesEdit, setCurrentPoliciesEdit] = useState('');
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);

  const [refreshPage, setRefreshPage] = useState(false);
  const filterOptions = [
    {
      label: 'Title (A to Z)',
      value: {filterKey: 'name', order: 'asc'},
    },
    {
      label: 'Title (Z to A)',
      value: {filterKey: 'name', order: 'desc'},
    },
    {
      label: 'Members Count (high to low)',
      value: {filterKey: 'member_count', order: 'desc'},
    },
    {
      label: 'Members Count (low to high)',
      value: {filterKey: 'member_count', order: 'asc'},
    },
  ];
  const currentActiveFilter = filterOptions.find(
    el => el.value.filterKey === meta.filterKey && el.value.order === meta.order,
  );
  const getCurrentPolicyData = policy => {
    return {
      name: policy?.name,
      health_plan: policy?.health_plan,
      health_plan_id: policy?.health_plan?.id,
      county: policy?.county,
      county_id: policy?.county?.id,
      cities_names: policy?.cities_names,
      cities: policy?.cities?.map(el => ({
        value: el.id,
        label: el.name,
      })),
      state: policy?.state,
      state_id: policy?.state?.id,
      outreach_staff: policy?.outreach_staff,
      outreach_staff_id: policy?.outreach_staff?.id,
      char_start_filter: policy?.char_start_filter,
      char_end_filter: policy?.char_end_filter,
    };
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handleSetSortFilter = selectedValue => {
    setMeta(prevMeta => ({...prevMeta, filterKey: selectedValue.filterKey, order: selectedValue.order}));
    handleRefreshPage();
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSelectAll = () => {
    const updatedCheckboxes = policiesList.map(checkbox => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setPoliciesList(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = index => {
    setPoliciesList(pre =>
      pre.map((item, itemIndex) => (itemIndex === index ? {...item, checked: !item.checked} : item)),
    );
  };
  const handleClosePoliciesModal = () => {
    setIsPoliciesModalvisible(false);
    setCurrentPoliciesEdit('');
  };
  const handleCloseHistoryModal = () => {
    setIsHistoryModalvisible(false);
    setCurrentPoliciesEdit('');
  };
  const handleOpenPoliciesModal = () => {
    setIsPoliciesModalvisible(true);
  };
  const handleOpenHistoryModal = id => {
    fetchPolicyHistory(id);

    setIsHistoryModalvisible(true);
  };
  const handlePolicySubmission = async (policy, setSubmitting) => {
    let city_ids = policy.cities?.map(c => c.value) || [];

    try {
      if (typeof currentPoliciesEdit === 'object') {
        const {id} = currentPoliciesEdit;

        const payload = {
          ...policy,
          city_ids,
        };

        const result = await updatePolicy({policy: {...payload}}, id);
        if (result) {
          handleClosePoliciesModal();
          handleRefreshPage();
        }
      } else {
        const payload = {
          ...policy,
          city_ids,
        };
        const result = await createPolicy({policy: {...payload}});
        if (result) {
          handleClosePoliciesModal();
          handleRefreshPage();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleDeletePolicy = async id => {
    const result = await deletePolicy(id);
    if (result) handleRefreshPage();
  };
  const handleBulkDeletePolicies = async () => {
    const selectedPolicyIds = policiesList?.filter(policy => policy.checked).map(policy => policy.id);
    const payload = {
      ids: selectedPolicyIds,
    };
    const result = await deleteBulkPoliciess(payload);
    if (result) handleRefreshPage();
  };
  const fetchPolicies = useCallback(async () => {
    setLoading(true);
    const result = await getPolicies(meta);

    if (result['policies']) {
      const data = result?.policies.map(item => ({...item, checked: selectAll}));
      setPoliciesList(data);
      setTotalCount(result?.meta.total_count);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  const fetchPolicyHistory = async id => {
    setHistoryLoading(true);
    const result = await getPolicyHistoy(id);
    if (result['paper_trail/versions']) {
      setHistory(result['paper_trail/versions']);
    }
    setHistoryLoading(false);
  };
  const fetchHealthPlan = useCallback(async searchString => {
    const result = await getHealthPlans({...initialMetaForTable, search: searchString});

    if (result['health_plans']) {
      const data = result?.health_plans;
      setHealthPlansList(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const debouncedFetchHealthPlan = useCallback(
    debounce(searchString => {
      fetchHealthPlan(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );

  const fetchStates = useCallback(async searchString => {
    const result = await getStates({...initialMetaForTable, search: searchString});

    if (result['states']) {
      const data = result?.states;
      setStatesList(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFetchStates = useCallback(
    debounce(searchString => {
      fetchStates(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );
  const fetchOutreachStaff = useCallback(async searchString => {
    const result = await getOutreachStaffs({...initialMetaForTable, search: searchString, status: true});

    if (result['outreach_staffs']) {
      const data = result?.outreach_staffs;
      setOutreachStaffList(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const debouncedFetchOutreachStaff = useCallback(
    debounce(searchString => {
      fetchOutreachStaff(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);
  useEffect(() => {
    debouncedFetchOutreachStaff();
  }, [debouncedFetchOutreachStaff]);
  useEffect(() => {
    debouncedFetchHealthPlan();
  }, [debouncedFetchHealthPlan]);
  useEffect(() => {
    debouncedFetchStates();
  }, [debouncedFetchStates]);
  useEffect(() => {
    setSelectedPolicies(
      selectAll
        ? policiesList.length
        : policiesList.reduce((accumulator, item) => {
            if (item.checked) {
              return accumulator + 1;
            } else return accumulator;
          }, 0),
    );
  }, [policiesList, selectAll]);

  return (
    <>
      <TableWrapper
        searchPlaceholder="Search Policies"
        setSearhQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        isLoading={loading}
        onPageChange={handlePageChange}
        selectedItems={selectedPolicies}
        upload={true}
        uploadKey={'Policies'}
        actionButtons={
          selectedPolicies > 0
            ? [
                {
                  label: `Delete ${selectedPolicies} Items`,
                  classes: 'danger-btn m-0-imp',
                  icon: <TrashSimple size={24} className="me-3" />,
                  handleClick: handleBulkDeletePolicies,
                },
              ]
            : [
                {
                  label: currentActiveFilter?.label || 'Sort by',
                  handlefilterSelect: selectedValue => handleSetSortFilter(selectedValue),
                  dropdown: true,
                  dropdownOptions: filterOptions,
                },

                {
                  label: 'Add Policy',
                  classes: 'primary-btn',
                  icon: <Plus size={24} className="me-3" />,
                  handleClick: () => {
                    handleOpenPoliciesModal();
                  },
                },
              ]
        }
      >
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-rows">
              <thead className="table-header">
                <tr>
                  <th>
                    <div className="form-check ps-3 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th>Title</th>
                  <th>Outreach Staff</th>
                  <th>Health Plan</th>
                  <th>State</th>
                  <th>County</th>
                  <th>Cities</th>
                  <th>Members Count</th>
                  <th>Start [A-Z]</th>
                  <th>End [A-Z]</th>
                </tr>
              </thead>
              <tbody>
                {policiesList?.map((policy, index) => (
                  <tr key={index}>
                    <td>
                      <div className="form-check ps-3 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={policy.checked}
                          onChange={() => handleCheckboxChange(index)}
                          value=""
                        />
                      </div>
                    </td>
                    <td className="fw-bold ">{highlightFirstMatch(policy.name, meta.search)}</td>
                    <td className="fw-bold ">
                      {highlightFirstMatch(capitalizeName(policy?.outreach_staff?.name), meta.search)}
                    </td>
                    <td>{highlightFirstMatch(policy?.health_plan?.name, meta.search)}</td>
                    <td>{highlightFirstMatch(capitalizeName(policy?.state?.name), meta.search)}</td>
                    <td>{highlightFirstMatch(capitalizeName(policy?.county?.name), meta.search)}</td>
                    <td>{highlightFirstMatch(policy?.cities_names, meta.search)}</td>
                    <td>
                      <span className="ps-5">{policy?.member_count}</span>
                    </td>
                    <td>
                      <span className="ps-4">{highlightFirstMatch(policy?.char_start_filter, meta.search)}</span>
                    </td>
                    <td>
                      <span className="ps-4">{highlightFirstMatch(policy.char_end_filter, meta.search)}</span>
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <Pencil
                          size={24}
                          className="opacity-50 me-3"
                          onClick={() => {
                            setCurrentPoliciesEdit(policy);
                            handleOpenPoliciesModal();
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Trash
                          size={24}
                          className=" opacity-50 me-3"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            policy?.id && handleDeletePolicy(policy.id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="History">
                        <Scroll
                          size={24}
                          className=" opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setCurrentPoliciesEdit(policy);
                            handleOpenHistoryModal(policy.id);
                          }}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </TableWrapper>
      {isPoliciesModalVisible && (
        <CustomModal
          size="sm"
          show
          onHide={handleClosePoliciesModal}
          heading={`${!currentPoliciesEdit ? 'Add' : 'Update'} Policy`}
        >
          <PoliciesModal
            handlePolicySubmission={handlePolicySubmission}
            handleClose={handleClosePoliciesModal}
            healthPlansList={healthPlansList}
            stateList={statesList}
            outreachStaffList={outreachStaffList}
            currentPolicyEdit={currentPoliciesEdit ? getCurrentPolicyData(currentPoliciesEdit) : ''}
          />
        </CustomModal>
      )}
      {isHistoryModalVisible && (
        <CustomModal size="sm" show onHide={handleCloseHistoryModal} heading={`Policy History`}>
          <HistoryModal
            handleClose={handleCloseHistoryModal}
            loading={historyLoading}
            history={history}
            currentPolicyEdit={currentPoliciesEdit}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Policies;
