import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {getAnswersStats, getSurveyStats} from 'containers/dashboard/api';
import BSLoader from 'components/common/loader';
import SurveyStats from './SurveyStats';
import AnswersStats from './AnswersStats';
import './dashboard.scss';
import {convertToDDMMYYYY, formatCurrentDate} from 'utils/common';

const Dashboardcontent = () => {
  const [surveyStats, setSurveyStats] = useState({
    totalQuestions: 'N/A',
    completedSurveys: 'N/A',
    pendingSurveys: 'N/A',
    inProgressSurveys: 'N/A',
    activeMembersCount: 'N/A'
  });
  const [answerStats, setAnswerStats] = useState([]);
  const [startDate, setStartDate] = useState(formatCurrentDate());
  const [endDate, setEndDate] = useState(formatCurrentDate());
  const [isLoadingData, setIsLoadingData] = useState(true);

  const mapSurveyStatstoState = statsResult => {
    const {
      total_questions = 'N/A',
      completed_surveys = 'N/A',
      pending_surveys = 'N/A',
      in_progress_surveys = 'N/A',
      active_members_count = 'N/A'
    } = statsResult;
    setSurveyStats({
      totalQuestions: total_questions,
      completedSurveys: completed_surveys,
      pendingSurveys: pending_surveys,
      inProgressSurveys: in_progress_surveys,
      activeMembersCount: active_members_count
    });
  };
  const handleGetData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const [surveyStatResult, answersStatsList] = await Promise.allSettled([
        getSurveyStats(convertToDDMMYYYY(startDate), convertToDDMMYYYY(endDate)),
        getAnswersStats(convertToDDMMYYYY(startDate), convertToDDMMYYYY(endDate)),
      ]);

      if (surveyStatResult.status === 'fulfilled') {
        mapSurveyStatstoState(surveyStatResult.value);
      }

      if (answersStatsList.status === 'fulfilled') {
        setAnswerStats(answersStatsList.value);
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsLoadingData(false);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <BSLoader />
      </div>
    );
  };

  const Statistics = useMemo(() => {
    if (isLoadingData) {
      return <Loader />;
    }

    return (
      <>
        <SurveyStats
          surveyStats={surveyStats}
          startDate={startDate}
          endDate={endDate}
          handleStartDate={setStartDate}
          handleEndDate={setEndDate}
        />
        <AnswersStats
          answerStats={answerStats}
          startDate={startDate}
          endDate={endDate}
          handleStartDate={setStartDate}
          handleEndDate={setEndDate}
        />
      </>
    );
  }, [isLoadingData, surveyStats, startDate, endDate, answerStats]);

  return <div className="container-fluid py-4">{Statistics}</div>;
};

export default Dashboardcontent;
