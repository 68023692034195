import classNames from 'classnames';
import Input from 'components/common/input';
import RadioButton from 'components/common/radio-button/RadioButton';
import SelectComponent from 'components/common/select';
import DateInput from 'components/common/date-picker';
import {question_type} from 'constants/common';
import React from 'react';
const renderOption = (questionType, question, selectedQuestion, questionsAnswers, handleChange) => {
  if (['dynamic'].includes(question_type[questionType])) {
    return (
      <div className={classNames('mt-2 ')}>
        <SelectComponent
          options={question?.answer_options.map(item => ({
            value: item.id || item.question_option_id,
            label: item.value,
            capacity_exceeded: item?.capacity_exceeded,
            color: item.color,
          }))}
          selectedValue={
            questionsAnswers[selectedQuestion]?.selected_answer_options[0]
              ? questionsAnswers[selectedQuestion].selected_answer_options[0]?.answer_option_id
              : undefined
          }
          placeholder="Select "
          handleChange={obj => {
            handleChange(question_type[questionType], obj.value, obj.label);
          }}
        />
        {/* <ErrorMessage component="p" name="question_type" /> */}
      </div>
    );
  } else if (question_type[questionType] === 'dropdown') {
    return (
      <div className={classNames('mt-2 ')}>
        <SelectComponent
          options={question?.answer_options
            .sort((a, b) => a?.order - b?.order)
            .map(item => ({
              value: item.id,
              label: item.value,
              capacity_exceeded: item?.capacity_exceeded,
              color: item.color,
            }))}
          selectedValue={
            questionsAnswers[selectedQuestion]?.selected_answer_options[0]
              ? questionsAnswers[selectedQuestion].selected_answer_options[0]?.answer_option_id
              : undefined
          }
          placeholder="Select "
          handleChange={obj => {
            handleChange(question_type[questionType], obj.value, obj.label);
          }}
        />
        {/* <ErrorMessage component="p" name="question_type" /> */}
      </div>
    );
  } else if (question_type[questionType] === 'multiple_choice') {
    return (
      <>
        {question &&
          question?.answer_options
            ?.sort((a, b) => a?.order - b?.order)
            .map(item => {
              return (
                <div className="mt-2">
                  <RadioButton
                    name={item.id}
                    id={item.id}
                    value={item.id}
                    text={item.value}
                    onChange={e => {
                      const {name} = e.target;
                      handleChange(question_type[questionType], name, item.value);
                    }}
                    checked={
                      item.id == questionsAnswers[selectedQuestion]?.selected_answer_options[0]?.answer_option_id
                    }
                  />
                </div>
              );
            })}
      </>
    );
  } else if (question_type[questionType] === 'text') {
    return (
      <div className="mt-2">
        <Input
          type="search"
          name="search"
          value={
            questionsAnswers[selectedQuestion]?.selected_answer_options[0]
              ? questionsAnswers[selectedQuestion].selected_answer_options[0]?.value
              : ''
          }
          onChange={e => {
            e.preventDefault();
            const value = e.target.value || '';
            handleChange(question_type[questionType], value, value);
          }}
          placeholder="Enter Value"
        />
      </div>
    );
  } else if (question_type[questionType] === 'phone') {
    return (
      <div className="mt-2">
        <Input
          type="tel"
          name="phone"
          value={
            questionsAnswers[selectedQuestion]?.selected_answer_options[0]
              ? questionsAnswers[selectedQuestion].selected_answer_options[0]?.value
              : ''
          }
          onChange={e => {
            e.preventDefault();
            const value = e.target.value || '';
            handleChange(question_type[questionType], value, value);
          }}
          placeholder="(XXX) XXX-XXXX"
        />
      </div>
    );
  } else if (question_type[questionType] === 'date') {
    return (
      <div className="mt-2">
        <DateInput
          handleChange={date => {
            handleChange(question_type[questionType], date, date);
          }}
          value={
            questionsAnswers[selectedQuestion]?.selected_answer_options[0]
              ? questionsAnswers[selectedQuestion].selected_answer_options[0]?.value
              : ''
          }
          name=""
          placeholderText="Select Date"
        />
      </div>
    );
  } else if (question_type[questionType] === 'multi_select') {
    return (
      <div className={classNames('mt-2 ')}>
        <SelectComponent
          options={question?.answer_options
            .map(item => ({
              value: item.id,
              label: item.value,
              capacity_exceeded: item?.capacity_exceeded,
              order: item.order,
            }))
            .sort((a, b) => a?.order - b?.order)}
          isMulti={true}
          selectedValue={
            questionsAnswers[selectedQuestion]?.selected_answer_options?.length > 0
              ? questionsAnswers[selectedQuestion].selected_answer_options.map(item => {
                  return {value: item.answer_option_id, label: item.value};
                })
              : []
          }
          placeholder="Select "
          handleChange={arr => {
            handleChange(question_type[questionType], arr);
          }}
        />
        {/* <ErrorMessage component="p" name="question_type" /> */}
      </div>
    );
  }
};
const QuestionType = ({question, handleChange, questionsAnswers, selectedQuestion}) => {
  return renderOption(question?.answer_type, question, selectedQuestion, questionsAnswers, handleChange);
};

export default QuestionType;
