import Input from 'components/common/input';
import {memberInitialValues, memberSchema} from 'constants/members';
import {ErrorMessage, Formik} from 'formik';
import SelectComponent from 'components/common/select';
import DateInput from 'components/common/date-picker';

const AddQuestionForm = ({handleQuestionSubmittion, handleClose}) => {
  return (
    <Formik
      initialValues={memberInitialValues}
      validationSchema={memberSchema}
      onSubmit={values => {
        handleQuestionSubmittion(values);
      }}
    >
      {({handleChange, values, handleSubmit, setFieldValue, errors}) => {
        console.log('errors', errors);
        return (
          <div className="question-modal">
            <form className="form-main" onSubmit={handleSubmit}>
              <hr className="custom-modal-line mt-0 " />

              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. John"
                    type="text"
                    label="First Name"
                    value={values.first_name}
                    name="first_name"
                    error={<ErrorMessage className="error-message" component="p" name="first_name" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. Doe"
                    type="text"
                    label="Last Name"
                    value={values.last_name}
                    name="last_name"
                    error={<ErrorMessage className="error-message" component="p" name="last_name" />}
                  />
                </div>
              </div>
              <div className="input-flex row">
                <div className=" col-sm-6">
                  <DateInput
                    handleChange={date => {
                      setFieldValue('date_of_birth', date);
                    }}
                    value={values.date_of_birth}
                    label="Select Date of Birth"
                    name="date_of_birth"
                    placeholderText="Date of Birth"
                    error={<ErrorMessage className="error-message" component="p" name="date_of_birth" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. Chicago"
                    type="text"
                    label="City"
                    value={values.city}
                    name="city"
                    error={<ErrorMessage className="error-message" component="p" name="city" />}
                  />
                </div>
              </div>
              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. 987123"
                    type="text"
                    label="CIN"
                    value={values.cin}
                    name="cin"
                    error={<ErrorMessage className="error-message" component="p" name="cin" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. LACARE, MAY 2023"
                    type="text"
                    label="MIF"
                    value={values.mif}
                    name="mif"
                    error={<ErrorMessage className="error-message" component="p" name="mif" />}
                  />
                </div>

                {/* <div class="mif-otr row">
                  <label htmlFor="mif_status">MIF Status</label>
                  <div className="select-wrapper">
                    <select
                      id="mif_status"
                      name="mif_status"
                      className="theme-input"
                      value={values.mif_status}
                      onChange={handleChange}
                    >
                      <option value="">MIF status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <ErrorMessage component="p" name="mif_status" />
                </div> */}
              </div>
              <div className="input-flex row">
                <div className="col-sm-6">
                  <SelectComponent
                    label="MIF Status"
                    options={[
                      {value: 'active', label: 'Active'},
                      {value: 'inactive', label: 'Inactive'},
                    ]}
                    selectedValue={values.mif_status}
                    placeholder="Select MIF Status "
                    handleChange={obj => {
                      setFieldValue('mif_status', obj.value);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="mif_status" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. 3523r"
                    type="text"
                    label="Account No."
                    value={values.account_number}
                    name="account_number"
                    error={<ErrorMessage className="error-message" component="p" name="account_number" />}
                  />
                </div>

                {/* <div class="enrollment-otr row">
                  <label htmlFor="enrollment_status">Enrollment</label>
                  <div class="select-wrapper">
                    <select
                      id="enrollment_status"
                      name="enrollment_status"
                      class="theme-input"
                      value={values.enrollment_status}
                      onChange={handleChange}
                    >
                      <option value="">Enrol status</option>
                      <option value="Excluded">Excluded</option>
                      <option value="Enrolled">Enrolled</option>
                      <option value="pending">pending</option>
                    </select>
                  </div>
                  <ErrorMessage component="p" name="enrollment_status" />
                </div> */}
              </div>
              <div className=" w-100">
                <SelectComponent
                  label="Enrollment Status"
                  options={[
                    {value: 'Excluded', label: 'Excluded'},
                    {value: 'Enrolled', label: 'Enrolled'},
                    {value: 'pending', label: 'pending'},
                  ]}
                  selectedValue={values.enrollment_status}
                  placeholder="Select Enrollment Status"
                  handleChange={obj => {
                    setFieldValue('enrollment_status', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="enrollment_status" />}
                />
              </div>
              <hr className="custom-modal-line mt-4 " />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <input className="primary-btn w-50" type="submit" name="submit" value={'Add Member'} />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddQuestionForm;
