import React, {useCallback, useEffect, useState} from 'react';
import {DownloadSimple, Scroll} from 'phosphor-react';
import TableWrapper from 'components/common/table-wrapper';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import CustomModal from 'components/common/modal';
import Addmemberform from './Addmemberform';
import {createMember, getMembers, deleteBulkMember, getExportMembers} from 'containers/members/api';
import {useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import {highlightFirstMatch, formatDateDiff} from 'utils/common';

import Tooltip from 'components/common/tooltip';

let timeout;

const SortableTableHeader = ({columnKey, label, sortConfig, onSortChange}) => {
  const isSorting = sortConfig.key === columnKey;
  const isAscending = isSorting && sortConfig.direction === 'asc';
  const isDescending = isSorting && sortConfig.direction === 'desc';

  const handleSort = () => {
    if (isAscending) {
      onSortChange(columnKey, 'desc');
    } else {
      onSortChange(columnKey, 'asc');
    }
  };

  return (
    <th onClick={handleSort} style={{cursor: 'pointer'}}>
      <div className="d-flex position-relative">
        <div className="d-flex flex-column cursor-pointer me-2 position-absolute top-50  end-100 translate-middle-y ">
          {/* <CaretUp size={12} color={isAscending ? '#000000' : '#00000050'} weight="fill" />
          <CaretDown color={isDescending ? '#000000' : '#00000050'} size={12} weight="fill" /> */}
        </div>
        <div>{label}</div>
      </div>
    </th>
  );
};
const columns = [
  {columnKey: 'name', label: 'Member Name', isSortable: true},
  {columnKey: 'date_of_birth', label: 'DOB', isSortable: true},
  {columnKey: 'phone_number', label: 'Phone Number', isSortable: true},
  {columnKey: 'health_plan_name', label: 'HP', isSortable: true},
  {columnKey: 'policy_name', label: 'Policy', isSortable: false},
  // {columnKey: 'account_number', label: 'Account#', isSortable: true},
  {columnKey: 'ecm', label: 'ECM Status', isSortable: true},
  {columnKey: 'last_survey_completed', label: 'Last Survey Complete', isSortable: true},
  {columnKey: 'in_person', label: 'IP', isSortable: true},
  {columnKey: 'telephonic', label: 'Tel', isSortable: true},
];
const Member = () => {
  const [membersList, setmembersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [meta, setMeta] = useState({...initialMetaForTable, mif_status: 'active'});
  const [loading, setLoading] = useState(true);
  const [selectedmembers, setSelectedmembers] = useState(0);
  const [ismemberModalVisible, setmemberIsModalVisible] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [sortConfig, setSortConfig] = useState({key: '', direction: ''});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({key, direction});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handleSetMifFilter = selectedValue => {
    setMeta(prevMeta => ({...prevMeta, mif_status: selectedValue}));
    handleRefreshPage();
  };
  const handlePageChange = value => {
    navigate({search: createSearchParams({page: value}).toString()});
    handleRefreshPage();
  };
  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };

  const handlememberSubmission = async member => {
    try {
      const result = await createMember(member);
      if (result) {
        handleClosememberModal();
        handleRefreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = membersList.map(checkbox => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setmembersList(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = index => {
    setmembersList(prevList =>
      prevList.map((item, itemIndex) => (itemIndex === index ? {...item, checked: !item.checked} : item)),
    );
  };

  const handleClosememberModal = () => {
    setmemberIsModalVisible(false);
  };
  const handleBulkDeleteMembers = async () => {
    const selectedMemberIds = membersList?.filter(member => member.checked).map(member => member.id);
    const payload = {
      ids: selectedMemberIds,
    };
    const result = await deleteBulkMember(payload);
    if (result) handleRefreshPage();
  };

  const handleOpenmemberModal = () => {
    setmemberIsModalVisible(true);
  };
  const fetchMembers = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getMembers({
        ...meta,
        page: searchParams.get('page') ? searchParams.get('page') : 1,
        key: sortConfig.key,
        order: sortConfig.direction,
      });
      console.log('memebers', result);
      if (result['members']) {
        const data = result?.members.map(item => ({...item, checked: selectAll}));
        setmembersList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, sortConfig.direction, sortConfig.key, searchParams.get('page')]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  useEffect(() => {
    setSelectedmembers(
      selectAll
        ? membersList.length
        : membersList.reduce((accumulator, item) => {
            if (item.checked) {
              return accumulator + 1;
            } else return accumulator;
          }, 0),
    );
  }, [membersList, selectAll]);

  const downloadCSV = async () => {
    try {
      const result = await getExportMembers({
        ...meta,
        key: sortConfig.key,
        order: sortConfig.direction,
      });

      const encodedData = result['members_encoded_data'];

      const decodedData = atob(encodedData);

      const blob = new Blob([decodedData], {type: 'text/csv'});

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member-data.csv');

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <TableWrapper
        searchPlaceholder="Search Members"
        setSearhQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
        onPageChange={handlePageChange}
        selectedItems={selectedmembers}
        isLoading={loading}
        meta={meta}
        setMeta={setMeta}
        handleRefreshPage={handleRefreshPage}
        actionButtons={
          selectedmembers > 0
            ? [
                // {
                //   label: `Delete ${selectedmembers} Items`,
                //   classes: 'danger-btn m-0-imp',
                //   icon: <TrashSimple size={24} className="me-3" />,
                //   handleClick: handleBulkDeleteMembers,
                // },
              ]
            : [
                // {
                //   label: 'Upload File',
                //   classes: 'secondary-btn',
                //   icon: <UploadSimple size={24} className="me-3" />,
                //   handleClick: () => {},
                // },
                {
                  label: 'Export Data',
                  classes: 'primary-btn',
                  icon: <DownloadSimple size={24} className="me-3" />,
                  handleClick: () => {
                    downloadCSV();
                  },
                },
                {
                  label:
                    meta.mif_status === 'active'
                      ? 'Active MIF'
                      : meta.mif_status === 'without_policy'
                      ? 'Without Policy'
                      : 'MIF Status',
                  selectedFilter: meta.mif_status,
                  handlefilterSelect: selectedValue => handleSetMifFilter(selectedValue),
                  dropdown: true,
                  dropdownOptions: [
                    {
                      label: 'Active',
                      value: 'active',
                    },
                    {
                      label: 'Without Policy',
                      value: 'without_policy',
                    },
                  ],
                },
              ]
        }
      >
        <div className="container-fluid ">
          <div className=" overflow-scroll">
            <table className="table table-rows ">
              {/* <thead className="table-header">
                <tr>
                  <th>
                    <div className="form-check ps-3 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Date of Birth</th>
                  <th>Phone Number</th>
                  <th>Health Plan</th>
                  <th>Account#</th>
                  {/* <th>MIF Status</th> */}
              {/*<th>ECM</th>
                  <th>
                    <div className="text-center">In Person Surveys</div>
                    <div className="text-center">Completed</div>
                  </th>
                  <th>
                    <div className="text-center">Telephonic Surveys</div>
                    <div className="text-center">Completed</div>
                  </th>
                </tr>
              </thead> */}
              <thead className="table-header">
                <tr>
                  {columns.map(column => (
                    <SortableTableHeader
                      key={column.columnKey}
                      columnKey={column.columnKey}
                      label={column.label}
                      isSortable={column.isSortable}
                      sortConfig={sortConfig}
                      onSortChange={handleSort}
                    />
                  ))}
                </tr>
              </thead>
              <tbody className="table-body">
                {membersList?.map((member, index) => (
                  <tr key={index}>
                    <td className="fw-bold  ">{highlightFirstMatch(member.name, meta.search)}</td>
                    <td className=" ">{member.date_of_birth}</td>
                    <td className=" ">{highlightFirstMatch(member.phone_number, meta.search)}</td>
                    <td className=" ">{highlightFirstMatch(member?.health_plan?.name, meta.search)}</td>
                    <td className="fw-bold  ">{member?.policy?.name}</td>
                    {/* <td className=" ">{highlightFirstMatch(member.account_number, meta.search)}</td> */}
                    {/* <td>
                      <div className={`status ${member.mif_status === 'active' ? 'active' : 'inactive'}`}>
                        {member.mif_status.charAt(0).toUpperCase() + member.mif_status.slice(1)}
                      </div>
                    </td> */}
                    <td>
                      <div
                        className={`status ms-3 ${
                          member.enrollment_status === 'NEW'
                            ? 'Excluded'
                            : member.enrollment_status === 'OU'
                            ? 'pending'
                            : 'pending'
                        }`}
                      >
                        {member.enrollment_status.charAt(0).toUpperCase() + member.enrollment_status.slice(1)}
                      </div>
                    </td>
                    <td>{formatDateDiff(member.last_survey_completed)}</td>
                    <td className=" ">{member.in_person}</td>
                    <td className=" ">{member.telephonic}</td>
                    <td>
                      <Tooltip title="History">
                        <Scroll
                          size={24}
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`/survey-history/${member.id}`);
                            localStorage.setItem('member', JSON.stringify(member));
                          }}
                        />
                      </Tooltip>
                    </td>
                    <td>
                      <button
                        className={`secondary-btns cursor-pointer  ${(member.pending_survey && 'pending') ||
                          (member.in_progress_survey && ' pending') ||
                          'Excluded'}  cursor-pointer `}
                        onClick={() => {
                          navigate(`/survey/${member.id}`, {state: member});
                          localStorage.setItem('member', JSON.stringify(member));
                        }}
                      >
                        {(member.pending_survey && 'Pending Survey') ||
                          (member.in_progress_survey && 'In Progress Survey') ||
                          'Start Survey'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </TableWrapper>
      {ismemberModalVisible && (
        <CustomModal size="sm" show onHide={handleClosememberModal} heading="Add Member">
          <Addmemberform handleQuestionSubmittion={handlememberSubmission} handleClose={handleClosememberModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Member;
