import React from 'react';
import Member from 'components/members/Member';

const Members = () => {
  return (
    <>
      <div className="main-contents">
        <h1>Members</h1>
        <Member />
      </div>
    </>
  );
};

export default Members;
