import * as yup from 'yup';

export const policySchema = yup.object().shape({
  name: yup.string().required('Required *'),
  health_plan_id: yup.string().required('Required *'),
  county_id: yup.string().nullable(),
  state_id: yup.string().nullable(),
  cities: yup.array().nullable(),
  outreach_staff_id: yup.string().required('Required *'),
  char_start_filter: yup.string().required('Required *'),
  char_end_filter: yup
    .string()
    .required('Required *')
    .matches(/^[A-Z]$/, 'Must be a single uppercase letter A to Z')
    .test('char_end_filter', 'End character should follow the start character in alphabetical order.', function(value) {
      const startChar = this.parent.char_start_filter;
      if (!startChar || !value) {
        // If either field is empty, don't perform this check
        return true;
      }

      return startChar.charCodeAt(0) < value.charCodeAt(0);
    }),
});

export const policyInitialValues = {
  name: '',
  health_plan_id: '',
  county_id: '',
  city_id: '',
  state_id: '',
  cities: [],
  outreach_staff_id: '',
  char_start_filter: 'A',
  char_end_filter: 'Z',
};
