// Import necessary modules for HTTP requests and toast notifications
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {RELEASES} from 'constants/endpoints';
import {toast} from 'react-toastify';
import {deleteApi, get, post, put} from 'services/network';

export const createRelease = async data => {
  try {
    const result = await post(`${RELEASES}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Release created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};

export const getReleases = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(`${RELEASES}?page=${params.page}&per=${params.perPage}&query=${params.search}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentRelease = async id => {
  try {
    const result = await get(`${RELEASES}/${id}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getLatestRelease = async () => {
  try {
    const result = await get(`${RELEASES}/latest`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function to update a version release
export const updateRelease = async (id, data) => {
  try {
    const result = await put(`${RELEASES}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Release updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};

// Function to delete a version release
export const deleteRelease = async id => {
  try {
    const result = await deleteApi(`${RELEASES}/${id}`);
    toast(<CustomToast variant={alertTypes.INFO} message="Release deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
