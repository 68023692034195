import React from 'react';
import Header, {LogoutDropdown} from './header';
import Sidebar from './sidebar';
import classNames from 'classnames';
import {revokeAuthToken} from 'containers/login/api';
import {isEmpty} from 'lodash';
import {removeAccessToken, removeUserInfoFromStorage} from 'utils/common';
import {useNavigate} from 'react-router-dom';
import {LOGIN_PATH} from 'constants/routePaths';
import {SurveyHeeader} from './surveyHeader';

const Layout = ({visibleSidebar = true, showSurvey = false, showSurveyHeader, children}) => {
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      const res = await revokeAuthToken();
      if (isEmpty(res)) {
        removeAccessToken();
        removeUserInfoFromStorage();
        navigate(LOGIN_PATH);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log('showSurveyHeader', showSurveyHeader);
  return (
    <div>
      {visibleSidebar && <Sidebar />}
      <main className={classNames('body-content mini-sidebar-margin me-3', {'has-sidebar': visibleSidebar})}>
        {!showSurvey && !showSurveyHeader ? (
          <Header logoutHandler={logoutHandler} />
        ) : showSurveyHeader ? (
          <SurveyHeeader>
            <SurveyHeeader.Logo />
            <SurveyHeeader.RightMenu logoutHandler={logoutHandler} />
          </SurveyHeeader>
        ) : (
          <SurveyHeeader>
            <SurveyHeeader.Logo />
            <LogoutDropdown logoutHandler={logoutHandler} />
          </SurveyHeeader>
        )}
        {children}
      </main>
    </div>
  );
};

export default Layout;
