import { GRANT_TYPE, toastContainerProps } from 'constants/common';
import { LOGIN_PATH } from 'constants/routePaths';
import { getAuthToken, revokeAuthToken } from 'containers/login/api';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRoutes from 'routes';
import {
  checkUserLoggedInStatus,
  diffHours,
  getAccessToken,
  getCreatedAt,
  getExpiresIn,
  getRefreshToken,
  getUserInfo,
  removeAccessToken,
  removeCreatedAt,
  removeExpiresIn,
  removeRefreshToken,
  removeUserInfoFromStorage,
  setAccessToken,
  setRefreshToken,
  setTokenCreatedAt,
  setTokenExpiresIn
} from 'utils/common';

import CustomToast from 'components/common/custom-toast';
import { alertTypes } from 'constants/common';
import { toast } from 'react-toastify';

function App() {
  const {loginData} = useSelector(state => state.login);
  const isStaging = process.env.REACT_APP_MODE === 'staging';
  const timeInterval = 15 * 60 * 1000;
  const REFRESH_HOURS = 2;
  const navigate = useNavigate();

  useEffect(()=>{
    const tokenExpireTime = parseInt(getCreatedAt()) + parseInt(getExpiresIn());
    const expiresDate = new Date(tokenExpireTime * 1000);

    const logoutHandler = async () => {
      try {
        const res = await revokeAuthToken();
        if (isEmpty(res)) {
          removeAccessToken();
          removeRefreshToken();
          removeCreatedAt();
          removeExpiresIn();
          removeUserInfoFromStorage();
          navigate(LOGIN_PATH);
          toast(<CustomToast variant={alertTypes.DANGER} message="Session Logged out. Please Login again" />);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if(new Date() >= expiresDate && getAccessToken()) {
      logoutHandler();
    }
  })

  useEffect(() => {
    let interval;
    const createdAt = new Date(parseInt(getCreatedAt()) * 1000);
    let hours = diffHours(createdAt, new Date());
    const refreshToken = async () => {
      if (hours >= REFRESH_HOURS) {
        const response = await getAuthToken(
          {email: getUserInfo()?.email, refreshToken: getRefreshToken()},
          GRANT_TYPE.REFRESH_TOKEN,
        );
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setTokenCreatedAt(response.created_at);
        setTokenExpiresIn(response.expires_in);
      }
    };
    interval = setInterval(() => {
      refreshToken();
    }, timeInterval);
    return () => clearInterval(interval);
  });

  return (
    <>
      {isStaging && (
        <div style={{backgroundColor: '#FF0000', padding: '10px', textAlign: 'center'}}>
          <strong>Staging Environment</strong>
        </div>
      )}
      <AppRoutes isLoggedIn={checkUserLoggedInStatus(loginData)} userRole={loginData?.result?.type} />
      <ToastContainer {...toastContainerProps} />
    </>
  );
}

export default App;
