import {POLICIES, COUNTIES, CITIES, STATES, HEALTH_PLANS, BULK_DELETE} from 'constants/endpoints';
import {result} from 'lodash';
import {deleteApi, get, post, put} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getPolicies = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    filterKey: obj?.filterKey ? obj.filterKey : '',
    order: obj?.order ? obj.order : '',
  };
  try {
    const result = await get(
      `${POLICIES}?page=${params.page}&per=${params.perPage}&query=${params.search}&sort=${params.filterKey}&order=${params.order}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getPolicyHistoy = async id => {
  try {
    const result = await get(`${POLICIES}/${id}/history?page=${1}&per=${200}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const createPolicy = async data => {
  try {
    const result = await post(`${POLICIES}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Policy created successfully" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={Object.values(error?.response?.data?.errors)[0] || error?.response?.statusText}
      />,
    );
    console.log(error);
  }
};
export const updatePolicy = async (data, id) => {
  try {
    const result = await put(`${POLICIES}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Policy updated successfully" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.errors?.base || error?.response?.statusText}
      />,
    );
    console.log(error);
  }
};
export const deletePolicy = async id => {
  try {
    const result = await deleteApi(`${POLICIES}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Policy deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const deleteBulkPoliciess = async data => {
  debugger;
  try {
    const result = await put(`${POLICIES}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Policies deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const getHealthPlans = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(`${HEALTH_PLANS}?page=${params.page}&per=${params.perPage}&query=${params.search}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getCounties = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    'filter[state_id]': obj?.state_id ? obj.state_id : '',
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${COUNTIES}?page=${params.page}&per=${params.perPage}&query=${params.search}&filter[state_id]=${params['filter[state_id]']}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getCities = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    'filter[state_id]': obj?.state_id ? obj.state_id : '',
    'filter[county_id]': obj?.county_id ? obj.county_id : '',
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${CITIES}?page=${params.page}&per=${params.perPage}&query=${params.search}&filter[state_id]=${params['filter[state_id]']}&filter[county_id]=${params['filter[county_id]']}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getStates = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(`${STATES}?page=${params.page}&per=${params.perPage}&query=${params.search}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
