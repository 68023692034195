import React from 'react';
import Leadcaremanagers from 'components/caremanager/Leadcaremanagers';
const Leadcaremanager = () => {
  return <>
   <div className="main-contents">
      <h1>LCM Supervisors</h1>
      <Leadcaremanagers/>
    </div>

  </>
};

export default Leadcaremanager;