import SurveyHistory from 'components/survey/SurveyHistory';
import {
  DASHBOARD_PATH,
  LOGIN_PATH,
  Leadcaremanager_PATH,
  Members_PATH,
  OutreachLeaders_PATH,
  OutreachStaff_PATH,
  POLICIES_PATH,
  QUESTIONS_PATH,
  RELEASE_PATH,
  RESET_PASSWORD_PATH,
  SURVEY_HISTORIES_PATH,
  SURVEY_HISTORY_PATH,
  SURVEY_PATH,
} from 'constants/routePaths';
import Dashboard from 'containers/dashboard';
import History from 'containers/history';
import Leadcaremanager from 'containers/leadcaremanager';
import Login from 'containers/login';
import Members from 'containers/members';
import OutreachLeaders from 'containers/outreachleaders';
import OutreachStaff from 'containers/outreachstaff';
import Policies from 'containers/policies';
import Questions from 'containers/questions';
import Releases from 'containers/releases';
import Reset from 'containers/reset-password';
import Survey from 'containers/survey';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Protected from 'routes/Protected';

const AppRoutes = ({isLoggedIn}) => {
  return (
      <Routes>
        {/* Guest Routes */}
        <Route path={LOGIN_PATH} element={<Login />} />
        <Route path={RESET_PASSWORD_PATH} element={<Reset />} />

        {/* Protected Routes */}
        <Route
          path={DASHBOARD_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path={QUESTIONS_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Questions />
            </Protected>
          }
        />
        <Route
          path={Leadcaremanager_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Leadcaremanager />
            </Protected>
          }
        />
        <Route
          path={OutreachLeaders_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OutreachLeaders />
            </Protected>
          }
        />
        <Route
          path={OutreachStaff_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <OutreachStaff />
            </Protected>
          }
        />
        <Route
          path={Members_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Members />
            </Protected>
          }
        />
        <Route
          path={POLICIES_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Policies />
            </Protected>
          }
        />
        <Route
          path={SURVEY_HISTORIES_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <History />
            </Protected>
          }
        />
        <Route
          path={SURVEY_HISTORY_PATH}
          element={
            <Protected visibleSidebar={false} isLoggedIn={isLoggedIn}>
              <SurveyHistory />
            </Protected>
          }
        />
        <Route
          path={SURVEY_PATH}
          element={
            <Protected visibleSidebar={false} showSurveyHeader={true} isLoggedIn={isLoggedIn}>
              <Survey />
            </Protected>
          }
        />
        <Route
          path={RELEASE_PATH}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Releases />
            </Protected>
          }
        />
      </Routes>
  );
};
export default AppRoutes;
