import React from 'react';
import {ErrorMessage, Formik} from 'formik';
import {PasswordSchema} from 'constants/outreachLeader';
import Input from 'components/common/input';

const ChangePasswordForm = ({handleSubmit, handleClose, currentOutreachLeeaderEdit}) => {
  const {name, username, email} = currentOutreachLeeaderEdit;
  console.log('currentOutreachLeeaderEdit', currentOutreachLeeaderEdit);
  return (
    <Formik
      initialValues={{password: '', confirmPassword: ''}}
      validationSchema={PasswordSchema}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {({handleChange, values, handleSubmit}) => {
        return (
          <div className="question-modal">
            <hr className="custom-modal-line mt-0 " />
            <div className="d-flex justify-content-center">
              <p>
                <b> Name:</b> {name}
                <br></br>
                <b> Username:</b> {username}
                <br></br>
                <b>Email:</b> {email}
              </p>
            </div>

            <form className="form-main" onSubmit={handleSubmit}>
              <Input
                handleChange={handleChange}
                placeholder="Password"
                type="password"
                label="New Password"
                value={values.password}
                name="password"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="password" />}
              />
              <Input
                handleChange={handleChange}
                placeholder="Password"
                type="password"
                label="Confirm Password"
                value={values.confirmPassword}
                name="confirmPassword"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="confirmPassword" />}
              />

              <hr className="custom-modal-line mt-4" />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <input className="primary-btn w-50" type="submit" name="submit" value={'Change Password'} />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
