import React from 'react';
import Select from 'react-select';

import './select.scss';
import {isArray} from 'lodash';
import {Check as CheckIcon} from 'phosphor-react';
import Check from 'components/common/check';
import classNames from 'classnames';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    color: state.isSelected ? '#00598B' : '#636363',
    padding: '6px 28px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
  }),
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      display: 'flex',
      // none of react-select's styles are passed to <Control />
      width: '100%',
      background: 'rgba(15, 15, 15, 0.05)',
      // border: '1px solid #00598B',
      borderRadius: '0  ',
      border: '1px solid #ced4da',
      borderColor: state.isFocused ? '#00b5d0 !important' : '#ced4da ',
      outline: 'none',
      boxShadow: 'none',
      svg: {
        transform: state.menuIsOpen ? 'rotate(180deg) !important' : 'rotate(0deg) !important',
        transition: '0.3s ease',
      },
    };
  },
  valueContainer: provided => ({
    ...provided,
    padding: '7px 28px',
    background: '#ffffff',
    // maxHeight: '2.8rem',
    // overflow: 'auto',
    // '&::-webkit-scrollbar ': {width: '4px'},
    // '&::-webkit-scrollbar-thumb ': {background: '#888', borderRadius: '6px', '&:hover': {background: '#555'}},

    // '&::-webkit-scrollbar ': {width: '12px'},

    // none of react-select's styles are passed to <Control />
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    height: '20px',
    overflow: 'hidden',
    color: '#0a0a0a',

    opacity: '0.45',
  }),
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      padding: '0px 20px 0 0',
      background: '#ffffff',
      svg: {
        width: '24px',
        height: '24px',
      },
    };
  },

  menu: (provided, state) => {
    return {
      ...provided,
      padding: '8px 0',
      background: '#FFFFFF',
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
      // borderRadius: '16px',
      zIndex: 100,
    };
  },
  menuPortal: base => ({...base, zIndex: 9999}),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {...provided, opacity, transition};
  },
  multiValue: provided => {
    return {...provided, backgroundColor: '#00b5d0', color: '#ffffff', borderRadius: '5px', padding: '3px 6px'};
  },
  multiValueLabel: provided => {
    return {...provided, color: '#ffffff'};
  },
  multiValueRemove: provided => {
    return {...provided, background: 'transparent', '&:hover': {color: '#A0A0A0', background: '#000000'}};
  },
};

const CustomOptionComponent = ({innerProps, innerRef, children, data}) => (
  <div ref={innerRef} {...innerProps}>
    {data.icons && <span className="icons me-2 ms-2">{data.icons}</span>}
    {children}
  </div>
);

function SelectComponent({
  name,
  label,
  className,
  options,
  selectedValue,
  handleChange,
  placeholder,
  disabled,
  error,

  handleChangeSearch,

  ...rest
}) {
  let value;
  if (isArray(selectedValue)) {
    value = selectedValue;
  } else {
    value = options.find(item => item.value === selectedValue) || '';
  }
  return (
    <div className={` ${className ? className : ''} input-otr`}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="select w-100">
        <Select
          value={value}
          options={options}
          width="200px"
          styles={customStyles}
          onChange={handleChange}
          placeholder={placeholder}
          closeMenuOnSelect={!rest.isMulti}
          // hideSelectedOptions={!rest.isMulti}
          isDisabled={disabled}
          className={` ${error ? 'border-danger' : ''}`}
          // onInputChange={inputValue => {
          //   handleChangeSearch(inputValue);
          // }}
          components={
            rest.isMulti
              ? {
                  Option: ({children, ...rest}) => (
                    <CustomOptionComponent {...rest}>
                      <div className="px-4 py-2 d-flex align-items-center">
                        <Check checked={rest.isSelected} name="check" />
                        {children}
                      </div>
                    </CustomOptionComponent>
                  ),
                }
              : {
                  Option: ({children, ...rest}) => {
                    return (
                      <CustomOptionComponent {...rest}>
                        <div
                          className={classNames(
                            'px-4 py-2 d-flex align-items-center justify-content-between custom-menu-option ',
                            {
                              'text-warning': rest.data?.color == 'orange',
                              'text-danger': rest.data?.capacity_exceeded,
                              'opacity-25': rest.isDisabled,
                            },
                          )}
                        >
                          <span className={`${rest.isSelected ? 'text-black' : 'opacity-75'})}`}>{children}</span>
                          {rest.isSelected ? (
                            <span className="me-3">
                              <CheckIcon size={24} color="#00b5d0" />
                            </span>
                          ) : null}
                        </div>
                      </CustomOptionComponent>
                    );
                  },
                }
          }
          {...(rest.isMulti ? {onInputChange: handleChangeSearch} : {onInputChange: handleChangeSearch})}
          {...rest}
        />
      </div>
      {error ? error : null}
    </div>
  );
}

export default SelectComponent;
