import React from 'react';
import './radio.scss';

const RadioButton = ({name, id, value, onChange, checked, text}) => {
  return (
    <div>
      <label htmlFor={id} className="radio-label">
        <input
          className="radio-input"
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="custom-radio" />
        {text}
      </label>
    </div>
  );
};

export default RadioButton;
