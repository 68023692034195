import React from 'react';
import HistoryComponent from 'components/history/History';

const History = () => {
  return (
    <>
      <div className="main-contents">
        <h1>History</h1>
        <HistoryComponent />
      </div>
    </>
  );
};

export default History;
