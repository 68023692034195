import React, {useState} from 'react';
import './CustomDropdown.scss';
import {CaretDown} from 'phosphor-react';

const CustomDropdown = ({label, options, selectedOption, onSelectOption}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    onSelectOption(option);
    toggleDropdown();
  };

  return (
    <div className="custom-dropdown custom-dropdown-container button-container d-flex flex-row">
      <button className="custom-dropdown-toggle" onClick={toggleDropdown}>
        {label}
        <CaretDown className="ms-2" size={20} />
      </button>
      {isOpen && (
        <ul className="custom-dropdown-options">
          {options.map(option => (
            <li
              key={option.value}
              className={`custom-dropdown-option ${selectedOption === option.value ? 'active' : ''}`}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
