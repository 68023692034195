import * as yup from 'yup';
const policySchema = yup.object().shape({
  id: yup.number(),
  name: yup.string(),
});
export const outreahAddSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  email: yup
    .string()
    .email()
    .required('Required *'),
  username: yup.string().required('Required *'),
  password: yup
    .string()
    .required('Required *')
    .min(6, 'too short (minimum is 6 characters)'),
  outreach_leader_id: yup.string().required('Required *'),
  location: yup.string().required('Required *'),
  policies: yup.array().of(policySchema),
});
export const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Required *')
    .min(6, 'Too short (minimum is 6 characters)'),
  confirmPassword: yup
    .string()
    .required('Required *')
    .oneOf([yup.ref('password'), null], 'Passwords must match'), // Add this validation
});

export const outreachStaffInitialValues = {
  name: '',
  email: '',
  username: '',
  password: '',
  outreach_leader_id: '',
  location: '',
  policies: [],
};
export const outreahEditSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  email: yup
    .string()
    .email()
    .required('Required *'),
  username: yup.string().required('Required *'),
  // password: yup
  //   .string()
  //   .required('Required *')
  //   .min(6, 'too short (minimum is 6 characters)'),
  outreach_leader_id: yup.string().required('Required *'),
  location: yup.string().required('Required *'),
  policies: yup.array().of(policySchema),
});
