import React from 'react';
import {ErrorMessage, Formik} from 'formik';
import {outreachStaffInitialValues, outreahAddSchema, outreahEditSchema} from 'constants/outreachStaff';
import Input from 'components/common/input';
import ToggleButton from 'components/common/togglebutton';
import SelectComponent from 'components/common/select';
import AsyncSelect from 'components/common/select/AsyncSelect';
import {getOutreachLeaders} from 'containers/outreachleaders/api';
import Tooltip from 'components/common/tooltip';

const Addoutreachstafform = ({
  handleQuestionSubmittion,
  handleClose,
  currentOutreachStaffEdit,
  outreachleaderList,
  policiesList,
  handlePolicySearch,
}) => {
  const isEditMode = typeof currentOutreachStaffEdit === 'object' ? true : false;

  return (
    <Formik
      initialValues={
        isEditMode
          ? {
              ...currentOutreachStaffEdit,
              outreach_leader_id: currentOutreachStaffEdit?.outreach_leader?.id,
              // password: '',
            }
          : outreachStaffInitialValues
      }
      validationSchema={isEditMode ? outreahEditSchema : outreahAddSchema}
      onSubmit={(values, {setSubmitting}) => {
        handleQuestionSubmittion(values, setSubmitting);
      }}
    >
      {({handleChange, values, errors, handleSubmit, setFieldValue, isSubmitting}) => {
        return (
          <div className="question-modal">
            <hr className="custom-modal-line mt-0 " />
            <form className="form-main" onSubmit={handleSubmit}>
              <div className="col-sm-6 mt-2 pt-1">
                <ToggleButton
                  label="Status"
                  handleChange={(name, value) => {
                    setFieldValue(name, !!value ? 'active' : 'inactive');
                  }}
                  value={values.status === 'active' ? 1 : 0}
                  name="status"
                />
              </div>
              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. John Doe"
                    type="text"
                    label="Name"
                    value={values.name}
                    name="name"
                    error={<ErrorMessage className="error-message" component="p" name="name" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. johndoe@gmail.com"
                    type="email"
                    label="Email"
                    value={values.email}
                    name="email"
                    disabled={isEditMode}
                    error={<ErrorMessage className="error-message" component="p" name="email" />}
                  />
                  {isEditMode && <p className="text-danger m-0 p-0 text-xs">Email can not be modified</p>}
                </div>
              </div>
              <div className="input-flex row">
                <div className={!isEditMode ? 'col-sm-6' : 'col-sm-12'}>
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. johndoe"
                    type="text"
                    label="Username"
                    value={values.username}
                    name="username"
                    error={<ErrorMessage className="error-message" component="p" name="username" />}
                  />
                </div>
                {!isEditMode ? (
                  <div className="col-sm-6">
                    <Input
                      handleChange={handleChange}
                      placeholder="Password"
                      type="password"
                      label="Password"
                      value={values.password}
                      name="password"
                      className="mb-1"
                      error={<ErrorMessage className="error-message" component="p" name="password" />}
                    />
                  </div>
                ) : null}
              </div>
              <div>
                <AsyncSelect
                  label="Outreach Leader"
                  options={
                    outreachleaderList.find(el => el.id === currentOutreachStaffEdit?.outreach_leader?.id) ||
                    typeof currentOutreachStaffEdit?.outreach_leader?.id == 'undefined'
                      ? outreachleaderList?.map(el => {
                          return {value: el.id, label: el.name};
                        })
                      : [
                          ...outreachleaderList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                          {
                            value: currentOutreachStaffEdit?.outreach_leader?.id,
                            label: currentOutreachStaffEdit?.outreach_leader?.name,
                          },
                        ]
                  }
                  dataCallback={async inputValue => {
                    const response = await getOutreachLeaders({search: inputValue, active: true});
                    const formatedOptions = response?.outreach_leaders?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    return formatedOptions;
                  }}
                  selectedValue={values.outreach_leader_id}
                  placeholder="Select Outreach leader "
                  handleChange={obj => {
                    setFieldValue('outreach_leader_id', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="outreach_leader_id" />}
                />
              </div>
              {isEditMode ? (
                <div>
                  <Input
                    label="Policies"
                    value={values.policies_name}
                    disabled={true}
                    error={<ErrorMessage className="error-message" component="p" name="policies" />}
                  />
                </div>
              ) : null}

              <Input
                handleChange={handleChange}
                placeholder="e.g. Titanium, Northern California"
                type="text"
                label="Default Location"
                value={values.location}
                name="location"
                error={<ErrorMessage className="error-message" component="p" name="location" />}
              />
              <hr className="custom-modal-line mt-4" />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                {isSubmitting ? (
                  <>
                    <span className="primary-btn w-50">
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Submitting...
                    </span>
                  </>
                ) : (
                  <input
                    className="primary-btn w-50"
                    type="submit"
                    name="submit"
                    value={isEditMode ? 'Save Changes' : 'Add Outreach Staff'}
                  />
                )}
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Addoutreachstafform;
