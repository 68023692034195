import React from 'react';

import {User, CaretDown, SignOut} from 'phosphor-react';
import BreadcrumbTrail from 'components/common/breadcrumb-trail';
import {SurveyHeeader} from '../surveyHeader';
import './header.scss';
import {Dropdown} from 'react-bootstrap';
import {getUserInfo} from 'utils/common';
import {LOGIN_ROLES} from 'constants/common';
export const LogoutDropdown = ({logoutHandler}) => (
  <Dropdown className="Dropdown">
    <Dropdown.Toggle variant="" id="">
      <div className="profile-otr">
        <div className="avatar">
          <User size={24} />
        </div>
        <span className="user-name">{getUserInfo()?.name || ''}</span>
        <CaretDown size={24} className="caretdown" />
      </div>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item
        href="#"
        onClick={e => {
          e.stopPropagation();
          logoutHandler();
        }}
      >
        <SignOut size={24} />
        <span className="ms-2">Logout</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);
const Header = ({logoutHandler}) => {
  const userRole = getUserInfo()?.type;
  return (
    <div className="header-main">
      <div className="container-fluid">
        <div className="wrapper">
          {userRole === LOGIN_ROLES.outreachstaff && <SurveyHeeader.Logo />}
          <div className="breadcrumb">{getUserInfo()?.type !== LOGIN_ROLES.outreachstaff && <BreadcrumbTrail />}</div>
          <LogoutDropdown logoutHandler={logoutHandler} />
        </div>
      </div>
    </div>
  );
};

export default Header;
