/* eslint-disable prefer-destructuring */
import axios from 'axios';
import CustomToast from 'components/common/custom-toast';
import { alertTypes } from 'constants/common';
import { LOGIN_PATH } from 'constants/routePaths';
import { toast } from 'react-toastify';
import {
  getAccessToken, removeAccessToken,
  removeCreatedAt,
  removeExpiresIn,
  removeRefreshToken,
  removeUserInfoFromStorage
} from 'utils/common';

const hookRequestInterceptorsWithAxiosInstance = instance =>
  instance.interceptors.request.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    config => {
      return config;
    },
  );

const hookResponseInterceptorsWithAxiosInstance = instance =>
  instance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    response => {
      return response;
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    error => {
      return Promise.reject(error);
    },
  );

function getAxios(tokenizeInstance, accessToken = null) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Hooking a request interceptor
  hookRequestInterceptorsWithAxiosInstance(instance);

  // Hooking a response interceptor
  hookResponseInterceptorsWithAxiosInstance(instance);

  if (tokenizeInstance) {
    const bearer = accessToken || getAccessToken();

    if (bearer) instance.defaults.headers.common.Authorization = `Bearer ${bearer}`;
  }

  return instance;
}

export const get = (
  url,
  tokenizeInstance = false,

  accessToken = null,
) =>
  getAxios(tokenizeInstance, accessToken)
    .get(url)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      if (err.response.status === 401) {
        removeAccessToken();
        removeRefreshToken();
        removeCreatedAt();
        removeExpiresIn();
        removeUserInfoFromStorage();
        toast(<CustomToast variant={alertTypes.DANGER} message="Session Logged out. Please Login again" />);
        window.location.href = LOGIN_PATH;
      }
    });

export const post = (url, data, tokenizeInstance = false) =>
  getAxios(tokenizeInstance)
    .post(url, data)
    .then(res => {
      return res.data !== ' ' ? res.data : {status: res.status};
    });

export const deleteApi = (
  url,
  tokenizeInstance = false,

  accessToken = null,
) =>
  getAxios(tokenizeInstance, accessToken)
    .delete(url)
    .then(res => {
      return res.data;
    });

export const put = (url, data, tokenizeInstance = false) =>
  getAxios(tokenizeInstance)
    .put(url, data)
    .then(res => {
      return res.data;
    });
export const patch = (url, data, tokenizeInstance = false) =>
  getAxios(tokenizeInstance)
    .patch(url, data)
    .then(res => {
      return res.data;
    });
