import React from 'react';
import {useNavigate} from 'react-router-dom';

const ThanksNote = () => {
  const navigate = useNavigate();

  return (
    <div className="thanks-note">
      <p className="heading-h3 text-center">
        Thank you for
        <br />
        completing the survey!
      </p>
      <button
        className="primary-btn w-100"
        onClick={() => {
          navigate(-1);
        }}
      >
        Go back to assigned members
      </button>
    </div>
  );
};

export default ThanksNote;
