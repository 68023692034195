import React from 'react';
import {ErrorMessage, Formik} from 'formik';
import Input from 'components/common/input';
import * as yup from 'yup';
import BSLoader from '../loader';

const UploadSchema = yup.object().shape({
  name: yup.string().required('Required *'),
  url: yup.string().required('Required *'),
});
const UploadForm = ({handleSubmit, handleClose, isLoading}) => {
  return (
    <Formik
      initialValues={{name: '', url: ''}}
      validationSchema={UploadSchema}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {({handleChange, values, handleSubmit}) => {
        return (
          <div className="question-modal">
            <hr className="custom-modal-line mt-0 " />

            <form className="form-main" onSubmit={handleSubmit}>
              <Input
                handleChange={handleChange}
                placeholder="Enter Name of the file"
                type="text"
                label="Name"
                value={values.name}
                name="name"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="name" />}
              />
              <Input
                handleChange={handleChange}
                placeholder="Enter URL of the file"
                type="text"
                label="URL"
                value={values.url}
                name="url"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="url" />}
              />

              <hr className="custom-modal-line mt-4" />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <input className="primary-btn w-50" type="submit" name="submit" disabled={isLoading} value={'submit'} />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default UploadForm;
