import {OUTREACH_STAFFS, BULK_DELETE} from 'constants/endpoints';
import {result} from 'lodash';
import {deleteApi, get, post, put, patch} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import { SEND_CONFIRMATION } from 'constants/endpoints';

export const getOutreachStaffs = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status,
  };
  try {
    const result = await get(
      `${OUTREACH_STAFFS}?page=${params.page}&per=${params.perPage}&query=${params.search}${
        params.status ? '&filter[status]=active' : ''
      }`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const createOutreachStaffs = async data => {
  try {
    const result = await post(`${OUTREACH_STAFFS}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateOutreachStaffs = async (data, id) => {
  try {
    const result = await put(`${OUTREACH_STAFFS}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateOutreachStaffPassword = async (data, id) => {
  try {
    const result = await patch(`${OUTREACH_STAFFS}/${id}/password_update`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff's password updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteOutreachStaffs = async id => {
  try {
    const result = await deleteApi(`${OUTREACH_STAFFS}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const deleteBulkOutreachStaffs = async data => {
  try {
    const result = await put(`${OUTREACH_STAFFS}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach Staff deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const sendOutreachStaffConfirmationEmail = async id => {
  try {
    const result = await post(`${OUTREACH_STAFFS}/${id}/${SEND_CONFIRMATION}`, null, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Outreach leader confirmation sent successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};