import {MEMBERS, BULK_DELETE, MEMBERS_EXPORT} from 'constants/endpoints';
import {result} from 'lodash';
import {deleteApi, get, post, put} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getMembers = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    per: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    'filter[mif_status]': obj?.mif_status ? obj.mif_status : '',
    key: obj?.key ? obj.key : '',
    order: obj?.order ? obj.order : '',
  };
  try {
    const result = await get(
      `${MEMBERS}?page=${params.page}&per=${params.per}&query=${params.search}&sort=${params.key}&order=${params.order}&filter[mif_status]=${params['filter[mif_status]']}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getMember = async id => {
  try {
    const result = await get(`${MEMBERS}/${id}`, true);
    return result;
  } catch (error) {
    console.error(error);
  }
};
export const getExportMembers = async obj => {
  const params = {
    search: obj?.search ? obj.search : '',
    'filter[mif_status]': obj?.mif_status ? obj.mif_status : '',
    key: obj?.key ? obj.key : '',
    order: obj?.order ? obj.order : '',
  };
  try {
    const result = await get(
      `${MEMBERS}/${MEMBERS_EXPORT}?query=${params.search}&sort=${params.key}&order=${params.order}&filter[mif_status]=${params['filter[mif_status]']}`,
      true,
    );
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const createMember = async data => {
  try {
    const result = await post(`${MEMBERS}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Member created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateMember = async (data, id) => {
  try {
    const result = await put(`${MEMBERS}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Member updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteMember = async id => {
  try {
    const result = await deleteApi(`${MEMBERS}/${id}`);
    toast(<CustomToast variant={alertTypes.INFO} message="Member deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
export const deleteBulkMember = async data => {
  try {
    const result = await put(`${MEMBERS}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Member deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(result);
  }
};
