import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import BSLoader from 'components/common/loader';
import {formatDateTimeZone} from 'utils/common';
const HistoryModal = ({handleSubmit, handleClose, currentPolicyEdit, history, loading}) => {
  const {name, member_count, outreach_staff, health_plan, county, state} = currentPolicyEdit;
  return (
    <div className="question-modal">
      <hr className="custom-modal-line mt-0 " />
      <div className="d-flex justify-content-center">
        <p>
          <b> Title:</b> {name}
          <br></br>
          <b> Outreach Staff:</b> {outreach_staff?.name}
          <br></br>
          <b>Health Plan:</b> {health_plan?.name || 'N/A'}
          <br></br>
          <b>State:</b> {state?.name || 'N/A'}
          <br></br>
          <b>County:</b> {county?.name || 'N/A'}
          <br></br>
          <b>Members:</b> {member_count}
        </p>
      </div>
      <div>
        {loading ? (
          <div className="w-100 text-center py-5">
            <BSLoader />
          </div>
        ) : history?.length ? (
          <div className=" overflow-y-scroll" style={{maxHeight: '300px'}}>
            <Accordion defaultActiveKey="0">
              {history.map(({meta, updated_at}, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{formatDateTimeZone(updated_at)}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <p>
                        Updated by
                        <br></br>
                        <b> Name:</b> {meta?.updated_by_jsonb?.name || ''}
                        <br></br>
                        <b> Email:</b> {meta?.updated_by_jsonb?.email || ''}
                        <br></br>
                        <b>Update:</b> {meta?.message || ''}
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ) : (
          <h6 className="text-center text-danger py-5">No record found</h6>
        )}
      </div>
      <hr className="custom-modal-line mt-4" />

      <div className="action gap-2">
        <button className="secondary-btn w-50" type="button" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};
export default HistoryModal;
