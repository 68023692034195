export const AUTH_ENDPOINT = 'oauth/token';
export const REVOKE_ENDPOINT = 'oauth/revoke';
export const FORGOT_PASSWORD_ENDPOINT = 'users/password';
export const QUESTIONS_ENDPOINT = 'api/v1/questions';
export const LEAD_CARE_MANAGER = 'api/v1/lead_care_managers';
export const OUTREACH_LEADERS = 'api/v1/outreach_leaders';
export const OUTREACH_STAFFS = 'api/v1/outreach_staffs';
export const MEMBERS = 'api/v1/members';
export const SURVEYS = 'api/v1/surveys';
export const SURVEY_COMMENTS = 'api/v1/comments'
export const SURVEYS_STATS = `${SURVEYS}/survey_stats`;
export const ANSWERS_STATS = `${SURVEYS}/answer_stats`;
export const SURVEY = 'api/v1/survey_responses';
export const SUBMIT_ANSWER = 'api/v1/answers';
export const ZIP_CODES = 'api/v1/zip_codes';
export const BULK_DELETE = 'destroy_bulk';
export const SEND_CONFIRMATION = 'send_confirmation';
export const POLICIES = 'api/v1/policies';
export const HEALTH_PLANS = 'api/v1/health_plans';
export const COUNTIES = 'api/v1/counties';
export const CITIES = 'api/v1/cities';
export const STATES = 'api/v1/states';
export const FILE_IMPORT = 'api/v1/file_imports';
export const UPLOAD_URL = 'upload_url';
export const MEMBERS_EXPORT = 'export';
export const RELEASES = 'api/v1/releases'
