import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {getAuthToken} from 'containers/login/api';
import {GRANT_TYPE} from 'constants/common';
import {toast} from 'react-toastify';
import {LOADING, LOGIN_USER} from 'store/action-types/login';

export const loginAction = (data, setSubmitting) => async dispatch => {
  setSubmitting(true);
  dispatch({type: LOADING, payload: true});
  try {
    const response = await getAuthToken(data, GRANT_TYPE.PASSWORD);
    if (response?.status_code === 200) {
      toast(
        <CustomToast
          variant={alertTypes.SUCCESS}
          message={response?.message ? response.message : 'Login successfully!'}
        />,
      );
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Invalid Credentials'} />);
    }
    dispatch({type: LOGIN_USER, payload: response});
    return response;
  } catch (error) {
    console.log(error);
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    dispatch({type: LOGIN_USER, payload: {}});
  } finally {
    dispatch({type: LOADING, payload: false});
    setSubmitting(false);
  }
};
