import Input from 'components/common/input';
import RadioButton from 'components/common/radio-button/RadioButton';
import {ReleaseSchema} from 'constants/releaseSchema';
import {ErrorMessage, Formik} from 'formik';

const AddEditReleaseForm = ({handleSubmit, handleClose, releaseToEdit = {}}) => {
  const {release_type = 'major', features = '', bugs = ''} = releaseToEdit;

  const appendBullet = text => {
    console.log('===  text:', text);
    const bullet = ' \u2022 ';
    return text + bullet;
  };

  return (
    <Formik
      initialValues={{release_type, features, bugs}}
      validationSchema={ReleaseSchema}
      onSubmit={values => {
        handleSubmit({id: releaseToEdit.id, ...values});
      }}
    >
      {({handleChange, values, handleSubmit, setFieldValue}) => {
        return (
          <div className="question-modal">
            <hr className="custom-modal-line mt-0 " />

            <form className="form-main" onSubmit={handleSubmit}>
              <div>
                <label className="input-label">Release type</label>
                <RadioButton
                  id={'major'}
                  name="major"
                  value={'major'}
                  text={'Major'}
                  onChange={e => {
                    const {name} = e.target;
                    setFieldValue('release_type', name);
                  }}
                  checked={values.release_type === 'major'}
                />
                <RadioButton
                  id={'minor'}
                  name="minor"
                  value={'minor'}
                  text={'Minor'}
                  onChange={e => {
                    const {name} = e.target;
                    setFieldValue('release_type', name);
                  }}
                  checked={values.release_type === 'minor'}
                />
              </div>

              <Input
                handleChange={handleChange}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    setFieldValue('features', appendBullet(values.features));
                  }
                }}
                placeholder="Features"
                type="textarea"
                rows={3}
                label="Features"
                value={values.features}
                name="features"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="password" />}
              />
              <Input
                handleChange={handleChange}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    setFieldValue('bugs', appendBullet(values.bugs));
                  }
                }}
                placeholder="Bugs"
                type="textarea"
                label="Bugs"
                value={values.bugs}
                name="bugs"
                className="mb-1"
                error={<ErrorMessage className="error-message" component="p" name="confirmPassword" />}
              />

              <hr className="custom-modal-line mt-4" />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <input
                  className="primary-btn w-50"
                  type="submit"
                  name="submit"
                  value={`${releaseToEdit?.id ? 'Edit' : 'Add'} Release`}
                />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddEditReleaseForm;
