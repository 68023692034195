import React, {useCallback, useEffect, useState} from 'react';
import {Pencil, Trash, Plus, TrashSimple, UploadSimple, Key, Check} from 'phosphor-react';
import TableWrapper from 'components/common/table-wrapper';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import CustomModal from 'components/common/modal';
import Addoutreachstafform from './Addoutreachstafform';
import ChangePasswordForm from './ChangePasswordForm';
import {
  getOutreachStaffs,
  createOutreachStaffs,
  updateOutreachStaffs,
  deleteOutreachStaffs,
  deleteBulkOutreachStaffs,
  updateOutreachStaffPassword,
} from 'containers/outreachstaff/api';
import {getPolicies} from 'containers/policies/api';
import {getOutreachLeaders} from 'containers/outreachleaders/api';
import {formatDate, highlightFirstMatch, formatDateTimeZone, capitalizeName, capitalizePlace} from 'utils/common';
import Tooltip from 'components/common/tooltip';
import debounce from 'lodash/debounce';
import {sendOutreachStaffConfirmationEmail} from 'containers/outreachstaff/api';
let timeout;

const Outreachstaff = () => {
  const [outreachstaffList, setOutreachstaffList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [loading, setLoading] = useState(true);
  const [selectedOutreachstaff, setSelectedOutreachstaff] = useState(0);
  const [isOutreachstaffModalVisible, setOutreachstaffModalVisible] = useState(false);
  const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
  const [currentOutreachStaffEdit, setcurrentOutreachStaffEdit] = useState('');
  const [outreachleaderList, setOutreachleaderList] = useState([]);
  const [policiesList, setPoliciesList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [policySearch, setPolicySearch] = useState('');
  const [outreachLeaderSearch, setOutreachLeaderSearch] = useState('');

  const debounceFn = useCallback((callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  }, []);

  const handleSetSearchQuery = value => {
    setMeta(prev => ({...prev, search: value}));
    debounceFn(DEBOUNCE_DELAY, handleRefreshPage);
  };

  const handlePageChange = value => {
    setMeta(prevMeta => ({...prevMeta, page: value}));
    setLoading(true);
    handleRefreshPage();
  };

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };

  const handleOutreachstaffSubmission = async (staff, setSubmitting) => {
    const staffPolicies = staff.policies.map(el => ({id: el.value, name: el.label}));

    try {
      if (typeof currentOutreachStaffEdit === 'object') {
        const {id} = staff;
        const result = await updateOutreachStaffs({outreach_staff: {...staff, policies: staffPolicies}}, id);
        if (result) {
          handleCloseOutreachstaffModal();
          handleRefreshPage();
        }
      } else {
        const result = await createOutreachStaffs({outreach_staff: {...staff, policies: staffPolicies}});
        if (result) {
          handleCloseOutreachstaffModal();
          handleRefreshPage();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClosePasswordModal = () => {
    setcurrentOutreachStaffEdit('');
    setPasswordModalVisible(false);
  };
  const handleChangepasswordSubmission = async data => {
    try {
      const {id} = currentOutreachStaffEdit;
      const result = await updateOutreachStaffPassword({outreach_staff: {password: data.password}}, id);
      if (result) {
        handleClosePasswordModal();
        // handleRefreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectAll = () => {
    const updatedCheckboxes = outreachstaffList.map(checkbox => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setOutreachstaffList(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = index => {
    setOutreachstaffList(prevList =>
      prevList.map((item, itemIndex) => (itemIndex === index ? {...item, checked: !item.checked} : item)),
    );
  };

  const handleCloseOutreachstaffModal = () => {
    setOutreachstaffModalVisible(false);
    setcurrentOutreachStaffEdit('');
  };

  const handleOpenOutreachstaffModal = () => {
    setOutreachstaffModalVisible(true);
  };

  const handleDeleteOutreachstaff = async id => {
    const result = await deleteOutreachStaffs(id);
    if (result) handleRefreshPage();
  };
  const handleBulkDeleteOutreachstaff = async () => {
    const selectedOutreachStaffIds = outreachstaffList?.filter(staff => staff.checked).map(staff => staff.id);
    const payload = {
      ids: selectedOutreachStaffIds,
    };
    const result = await deleteBulkOutreachStaffs(payload);
    if (result) handleRefreshPage();
  };

  const fetchPolicies = useCallback(async () => {
    try {
      const result = await getPolicies({...initialMetaForTable, search: policySearch});
      if (result['policies']) {
        const data = result?.policies;
        setPoliciesList(data);
      }
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchOutreachStaff = useCallback(
    async searchString => {
      setLoading(true);
      try {
        const result = await getOutreachStaffs(meta);
        console.log('outreach staffs are', result);
        if (result['outreach_staffs']) {
          const data = result?.outreach_staffs.map(item => ({
            ...item,
            checked: selectAll,
            created_at: item.created_at,
            updated_at: item.updated_at,
          }));
          setOutreachstaffList(data);
          setTotalCount(result?.meta.total_count);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refreshPage, meta, selectAll],
  );

  const debouncedFetchPolicies = useCallback(
    debounce(searchString => {
      fetchPolicies(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );

  useEffect(() => {
    debouncedFetchPolicies(policySearch);
  }, [policySearch]);

  const handlePolicySearch = searchString => {
    setPolicySearch(searchString);
  };

  useEffect(() => {
    fetchOutreachStaff();
  }, [fetchOutreachStaff]);

  useEffect(() => {
    setSelectedOutreachstaff(
      selectAll
        ? outreachstaffList.length
        : outreachstaffList.reduce((accumulator, item) => {
            if (item.checked) {
              return accumulator + 1;
            } else return accumulator;
          }, 0),
    );
  }, [outreachstaffList, selectAll]);
  const fetchOutreachLeaders = useCallback(async searchString => {
    try {
      const result = await getOutreachLeaders({...initialMetaForTable, search: searchString, active: true});
      if (result?.outreach_leaders) {
        setOutreachleaderList(result.outreach_leaders);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const debouncedFetchOutreachLeaders = useCallback(
    debounce(searchString => {
      fetchOutreachLeaders(searchString);
    }, DEBOUNCE_DELAY),
    [],
  );
  useEffect(() => {
    debouncedFetchOutreachLeaders(outreachLeaderSearch);
  }, [debouncedFetchOutreachLeaders, outreachLeaderSearch]);
  const handleOutreachLeaderSearch = searchString => {
    setOutreachLeaderSearch(searchString);
  };
  return (
    <>
      <TableWrapper
        searchPlaceholder="Search Outreach Staff"
        setSearhQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        isLoading={loading}
        onPageChange={handlePageChange}
        selectedItems={selectedOutreachstaff}
        upload={true}
        uploadKey={'OutreachStaff'}
        actionButtons={
          selectedOutreachstaff > 0
            ? [
                {
                  label: `Delete ${selectedOutreachstaff} Items`,
                  classes: 'danger-btn m-0-imp',
                  icon: <TrashSimple size={24} className="me-3" />,
                  handleClick: handleBulkDeleteOutreachstaff,
                },
              ]
            : [
                {
                  label: 'Add Outreach Staff',
                  classes: 'primary-btn',
                  icon: <Plus size={24} className="me-3" />,
                  handleClick: handleOpenOutreachstaffModal,
                },
              ]
        }
      >
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-rows">
              <thead className="table-header">
                <tr>
                  <th>
                    <div className="form-check ps-3 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Username</th>
                  <th>Outreach Leader</th>
                  <th>Last Login</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {outreachstaffList?.map((outreachstaff, index) => (
                  <tr key={index}>
                    <td>
                      <div className="form-check ps-3 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={outreachstaff.checked}
                          onChange={() => handleCheckboxChange(index)}
                          value=""
                        />
                      </div>
                    </td>
                    <td>{highlightFirstMatch(capitalizeName(outreachstaff.name), meta.search)}</td>
                    <td>{highlightFirstMatch(outreachstaff.email, meta.search)}</td>
                    <td>{highlightFirstMatch(outreachstaff.username, meta.search)}</td>
                    <td>{capitalizeName(outreachstaff?.outreach_leader?.name)}</td>
                    <td>{outreachstaff.last_sign_in_at}</td>
                    <td> {outreachstaff.created_at}</td>
                    <td>
                      <Tooltip title="Edit">
                        <Pencil
                          size={24}
                          className="opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setcurrentOutreachStaffEdit({
                              ...outreachstaff,
                              policies: outreachstaff?.policies?.map(el => ({value: el.id, label: el.name})),
                            });
                            handleOpenOutreachstaffModal();
                          }}
                        />
                      </Tooltip>{' '}
                      <Tooltip title="Delete">
                        <Trash
                          size={24}
                          className="ms-3 opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            outreachstaff?.id && handleDeleteOutreachstaff(outreachstaff.id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Change Password">
                        <Key
                          size={24}
                          className="ms-3 opacity-50"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setcurrentOutreachStaffEdit({
                              ...outreachstaff,
                              policies: outreachstaff?.policies?.map(el => ({value: el.id, label: el.name})),
                            });
                            setPasswordModalVisible(true);
                          }}
                        />
                      </Tooltip>
                      {!outreachstaff.confirmed ? (
                        <Tooltip title="Send Confirmation Email">
                          <UploadSimple
                            size={24}
                            className="ms-3 opacity-50"
                            style={{cursor: 'pointer'}}
                            onClick={async () => {
                              await sendOutreachStaffConfirmationEmail(outreachstaff?.id);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="User Confirmed">
                          <Check size={24} className="ms-3 opacity-50" />
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </TableWrapper>
      {isOutreachstaffModalVisible && (
        <CustomModal
          size="sm"
          show
          onHide={handleCloseOutreachstaffModal}
          heading={`${currentOutreachStaffEdit ? 'Edit' : 'Add'} Outreach Staff`}
        >
          <Addoutreachstafform
            handleQuestionSubmittion={handleOutreachstaffSubmission}
            handleClose={handleCloseOutreachstaffModal}
            currentOutreachStaffEdit={currentOutreachStaffEdit}
            outreachleaderList={outreachleaderList}
            policiesList={policiesList}
            handlePolicySearch={handlePolicySearch}
            handleOutreachLeaderSearch={handleOutreachLeaderSearch}
            outreachLeaderSearch={outreachLeaderSearch}
          />
        </CustomModal>
      )}
      {isPasswordModalVisible && (
        <CustomModal size="sm" show onHide={handleClosePasswordModal} heading={`Change Password`}>
          <ChangePasswordForm
            handleSubmit={handleChangepasswordSubmission}
            handleClose={handleClosePasswordModal}
            currentOutreachStaffEdit={currentOutreachStaffEdit}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Outreachstaff;
