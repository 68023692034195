import React from 'react';
import UploadForm from './UploadForm';
import CustomModal from '../modal';

function index({handleSubmission, handleCloseModal, isLoading}) {
  return (
    <CustomModal size="sm" show onHide={handleCloseModal} heading={`Attach Files`}>
      <UploadForm handleSubmit={handleSubmission} handleClose={handleCloseModal} isLoading={isLoading} />
    </CustomModal>
  );
}

export default index;
