import Input from 'components/common/input';
import {outreachLeaderInitialValues, outreahLeaderAddSchema, outreahLeaderEditSchema} from 'constants/outreachLeader';
import ToggleButton from 'components/common/togglebutton';
import {LOGIN_ROLES} from 'constants/common';
import {getUserInfo} from 'utils/common';
import {ErrorMessage, Formik} from 'formik';

const OutreachForm = ({handleQuestionSubmittion, handleClose, currentOutreachLeeaderEdit}) => {
  const isEditMode = typeof currentOutreachLeeaderEdit === 'object' ? true : false;
  return (
    <Formik
      initialValues={isEditMode ? {...currentOutreachLeeaderEdit, password: ''} : outreachLeaderInitialValues}
      validationSchema={isEditMode ? outreahLeaderEditSchema : outreahLeaderAddSchema}
      onSubmit={(values, {setSubmitting}) => {
        handleQuestionSubmittion(values, setSubmitting);
      }}
    >
      {({handleChange, values, handleSubmit, setFieldValue, isSubmitting}) => {
        return (
          <div className="question-modal">
            <form className="form-main" onSubmit={handleSubmit}>
              <hr className="custom-modal-line mt-0 " />
              {getUserInfo()?.type === LOGIN_ROLES.outreachleader && getUserInfo()?.super_admin && (
                <div className="col-sm-6 mt-2 pt-1">
                  <ToggleButton
                    label="Superadmin"
                    handleChange={(name, value) => {
                      setFieldValue(name, value ? true : false);
                    }}
                    value={values.super_admin ? 1 : 0}
                    name="super_admin"
                  />
                </div>
              )}

              <div className="col-sm-6 mt-2 pt-1">
                <ToggleButton
                  label="Status"
                  handleChange={(name, value) => {
                    setFieldValue(name, !!value ? 'active' : 'inactive');
                  }}
                  value={values.status === 'active' ? 1 : 0}
                  name="status"
                />
              </div>

              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. John Doe"
                    type="text"
                    label="Name"
                    value={values.name}
                    name="name"
                    error={<ErrorMessage className="error-message" component="p" name="name" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. johndoe@gmail.com"
                    type="email"
                    label="Email"
                    value={values.email}
                    name="email"
                    disabled={isEditMode}
                    error={<ErrorMessage className="error-message" component="p" name="email" />}
                  />
                </div>
              </div>
              <div className="input-flex row">
                <div className={!isEditMode ? 'col-sm-6' : 'col-sm-12'}>
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. johndoe"
                    type="text"
                    label="Username"
                    value={values.username}
                    name="username"
                    error={<ErrorMessage className="error-message" component="p" name="username" />}
                  />
                </div>
                {!isEditMode ? (
                  <div className="col-sm-6">
                    <Input
                      handleChange={handleChange}
                      placeholder="Password"
                      type="password"
                      label="Password"
                      value={values.password}
                      name="password"
                      className="mb-1"
                      error={<ErrorMessage className="error-message" component="p" name="password" />}
                    />
                  </div>
                ) : null}
              </div>

              <Input
                handleChange={handleChange}
                placeholder="e.g. Titanium, Northern California"
                type="text"
                label="Default Location"
                value={values.location}
                name="location"
                error={<ErrorMessage className="error-message" component="p" name="location" />}
              />
              <hr className="custom-modal-line mt-4 " />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                {isSubmitting ? (
                  <>
                    <span className="primary-btn w-50">
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Submitting...
                    </span>
                  </>
                ) : (
                  <input
                    className="primary-btn w-50"
                    type="submit"
                    name="submit"
                    value={isEditMode ? 'Save Changes' : 'Add Outreach Leader'}
                  />
                )}
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default OutreachForm;
