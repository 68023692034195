import React from 'react';
import './toggle.scss';

function ToggleButton({label, left, right, handleChange, value, name, ...rest}) {
  return (
    <>
      <label className="form-check-label heading-xsb mb-2" htmlFor={name}>
        {label}
      </label>

      <div className="form-check form-switch toggle-main ">
        {left && <span className=" position-absolute top-50  start-0 translate-middle z-3 ">{left}</span>}
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          name={name}
          id={name}
          onChange={e => handleChange(name, e.target.checked ? 1 : 0)}
          checked={value === 1 ? true : false}
          {...rest}
        />
        {right && <span className=" position-absolute top-50  start-100 translate-middle z-3">{right}</span>}
      </div>
    </>
  );
}

export default ToggleButton;
