import {getSurveyAnswers, submitAllQuestions} from 'containers/survey/api';
import {Pencil} from 'phosphor-react';
import PrevIcon from '../../assets/images/arrow-left.png';
import React, {useCallback, useEffect, useState} from 'react';
import BSLoader from 'components/common/loader';

const QuestionsOverview = ({
  questionsAnswers,
  setCurrentQuestion,
  setStack,
  setQuestionsOverview,
  setThanksNote,
  id,
  surveyId,
  stack,
}) => {
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [reviewQuestionAswers, setReviewQuestionAswers] = useState([]);

  const fetchSurveyResponse = useCallback(async () => {
    try {
      const result = await getSurveyAnswers(surveyId);
      if (result.answers) {
        let newResponse = result.answers.map(answer => ({
          ...answer,
          actualIndex: questionsAnswers.findIndex(ans => ans.id === answer.id),
        }));
        setReviewQuestionAswers(newResponse);
      } else {
        setReviewQuestionAswers(questionsAnswers);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOverviewLoading(false);
    }
  }, [questionsAnswers, surveyId]);

  useEffect(() => {
    fetchSurveyResponse();
  }, [fetchSurveyResponse]);
  return overviewLoading ? (
    <div className="text-center py-5 bg-white w-100">
      <BSLoader className="my-5" />
    </div>
  ) : (
    <div className="questions-overview container-fluid py-4">
      <div className="review-action">
        <div>
          <p className="heading-h5">Review Your Answers</p>
          <p>Please review your answers before submitting the survey</p>
        </div>
        <div className="surveyreview-btn">
          <button className="secondary-btn previous-btn me-2" onClick={() => setQuestionsOverview(false)}>
            <img src={PrevIcon} alt="back arrow" className="pe-3" /> Go to Survey
          </button>
          <button
            className="primary-btn"
            onClick={async () => {
              const result = await submitAllQuestions(surveyId);
              if (result) setThanksNote(true);
            }}
          >
            Submit Survey
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-rows">
          <thead className="table-header">
            <tr>
              <th>No.</th>
              <th>Question</th>
              <th>Answer</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {reviewQuestionAswers
              .filter((question, index) => stack.includes(index) || question?.selected_answer_options?.length > 0)
              .map((question, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{question?.question_text}</td>
                  <td>
                    {question?.selected_answer_options?.map(ele => {
                      return <span>{`${ele.value} `}</span>;
                    })}
                  </td>

                  <td>
                    <Pencil
                      size={24}
                      className="opacity-50"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setQuestionsOverview(false);
                        setCurrentQuestion(question.actualIndex);
                        setStack(pre => [...pre, question.actualIndex]);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuestionsOverview;
