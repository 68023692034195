import React from 'react';
import Policies from 'components/policies/Policies';
const index = () => {
  return (
    <>
      <div className="main-contents">
        <h1>Policies</h1>
        <Policies />
      </div>
    </>
  );
};

export default index;
