import {Eye, EyeSlash} from 'phosphor-react';
import {useState} from 'react';
import './input.scss';

function Input({
  className,
  handleChange,
  type,
  name,
  value,
  placeholder,
  Icon,
  label,
  isError,
  error,
  disabled,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  let inputElement;

  switch (type) {
    case 'textarea':
      inputElement = (
        <textarea
          onChange={handleChange}
          name={name}
          className={`theme-input input w-100 ${isError ? 'border-danger' : ''} ${
            disabled ? 'muted-bgr text-muted' : ''
          }`}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
      );
      break;
    case 'password':
      inputElement = (
        <div className="position-relative w-100">
          <input
            type={showPassword ? 'text' : 'password'}
            onChange={handleChange}
            className={`theme-input input w-100 z-1 ${isError ? 'border-danger' : ''} ${
              disabled ? 'muted-bgr text-muted' : ''
            }`}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
          <span
            className="position-absolute top-50 end-0 translate-middle p-1 pe-3 z-3 cursor-pointer"
            onClick={handleTogglePassword}
          >
            {showPassword ? <Eye /> : <EyeSlash />}
          </span>
        </div>
      );
      break;
    default:
      inputElement = (
        <>
          <input
            onChange={handleChange}
            name={name}
            className={`theme-input input w-100 ${isError ? 'border-danger' : ''} ${
              disabled ? 'muted-bgr text-muted' : ''
            }`}
            value={value}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
          {Icon && <Icon className="search-icon ms-1 opacity-50" size={24} />}
        </>
      );
      break;
  }

  return (
    <div className={` ${className ? className : ''} input-otr `}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      {inputElement}
      {error ? error : null}
    </div>
  );
}

export default Input;
