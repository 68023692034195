import {alertIcons, alertTypes} from 'constants/common';
import {Alert} from 'react-bootstrap';
import './alert.scss';

const CustomAlert = ({variant = alertTypes.PRIMARY, closeAlert, children}) => {
  return (
    <Alert variant={variant} onClose={closeAlert} dismissible>
      <div className="d-flex">
        <div>{alertIcons[variant]}</div>
        <div className="px-3">{children}</div>
      </div>
    </Alert>
  );
};

export default CustomAlert;
