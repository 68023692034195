import {OverlayTrigger, Tooltip} from 'react-bootstrap';

function index({children, placement = 'bottom', title, id}) {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={<Tooltip id={`tooltip-${id ? id : title}`}>{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}

export default index;
