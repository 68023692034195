import {QUESTIONS_ENDPOINT, BULK_DELETE} from 'constants/endpoints';
import {deleteApi, get, post, put} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getQuestions = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${QUESTIONS_ENDPOINT}?page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateQuestions = async (data, id) => {
  try {
    const result = await put(`${QUESTIONS_ENDPOINT}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Question updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteQuestion = async id => {
  try {
    const result = await deleteApi(`${QUESTIONS_ENDPOINT}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Question deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteBulkQuestion = async data => {
  try {
    const result = await put(`${QUESTIONS_ENDPOINT}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Question deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const createQuestion = async data => {
  try {
    const result = await post(`${QUESTIONS_ENDPOINT}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="Question created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
