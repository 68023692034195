import * as yup from 'yup';

// export const questionsSchema = yup.object().shape({
//   description: yup.string().required('Required *'),
//   question_type: yup.string().required('Required *'),
//   question_options: yup
//     .array()
//     .of(
//       yup.object().shape({
//         value: yup.string().required('Required *'),
//         // answer: yup.boolean().required(),
//       }),
//     )
//     .when('question_type', {
//       is: 'selected',
//       then: yup.array().of(
//         yup.object().shape({
//           value: yup.string().required('Required *'),
//           // id: yup.boolean().required()s,
//         }),
//       ),
//     }),
//   status: yup.string(),
// });
export const questionsSchema = yup.object().shape({
  description: yup.string().required('Required *'),
  question_type: yup.string().required('Required *'),
  next_question_id: yup.string().nullable(),
  question_options: yup.array().when('question_type', {
    is: val => !['dynamic', 'text', 'date', 'phone'].includes(val), // When question_type is not equal to "text"
    then: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required('Option value is required'),
          next_question_id: yup.string().nullable(),
          order: yup.number(),
        }),
      )
      .min(1, 'At least one option is required') // Require at least one option
      .required('Required *'), // Mark question_options as required
    otherwise: yup
      .array() // When question_type is equal to "text"
      .of(
        yup.object().shape({
          value: yup.string().nullable(),
        }),
      ),
  }),
  status: yup.string(),
  is_first_question: yup.boolean(),
  dashboard_display: yup.boolean(),
});
export const questionsInitialValues = {
  description: '',
  question_type: '',
  next_question_id: null,
  question_options: [{value: '', next_question_id: '', order: 0}],
  status: 'active',
  is_first_question: false,
  dashboard_display: false,
};
