import {ANSWERS_STATS, SURVEYS_STATS} from 'constants/endpoints';
import {get} from 'services/network';
export const getSurveyStats = async (startDate, endDate) => {
  try {
    const result = await get(`${SURVEYS_STATS}?start_date=${startDate}&end_date=${endDate}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getAnswersStats = async (startDate, endDate) => {
  try {
    const result = await get(`${ANSWERS_STATS}?start_date=${startDate}&end_date=${endDate}`, true);
    return result;
  } catch (error) {
    console.log(error);
  }
};
