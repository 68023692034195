import {LEAD_CARE_MANAGER, BULK_DELETE, ZIP_CODES} from 'constants/endpoints';
import {result} from 'lodash';
import {deleteApi, get, post, put} from 'services/network';
import {toast} from 'react-toastify';
import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';

export const getLeadCareManagers = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    filter: obj?.status ? obj.status : '',
  };
  try {
    const result = await get(
      `${LEAD_CARE_MANAGER}?filter[status]=${params.filter}&page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getZipCodes = async obj => {
  console.log('obj', obj);
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    filter: obj?.status ? obj.status : '',
  };
  try {
    const result = await get(
      `${ZIP_CODES}??filter[status]=${params.filter}&page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const createLeadCareManager = async data => {
  try {
    const result = await post(`${LEAD_CARE_MANAGER}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="LCM Supervisor created successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const updateLeadCareManager = async (data, id) => {
  console.log('data', data);
  try {
    const result = await put(`${LEAD_CARE_MANAGER}/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="LCM Supervisor updated successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText} />);
    console.log(error);
  }
};
export const deleteLeadCareManager = async id => {
  try {
    const result = await deleteApi(`${LEAD_CARE_MANAGER}/${id}`, true);
    toast(<CustomToast variant={alertTypes.INFO} message="LCM Supervisor deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText || 'test'} />);
    console.log(result);
  }
};
export const deleteBulkLeadCareManager = async data => {
  try {
    const result = await put(`${LEAD_CARE_MANAGER}/${BULK_DELETE}`, data, true);
    toast(<CustomToast variant={alertTypes.INFO} message="LCM Supervisor deleted successfully" />);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error?.response?.statusText || 'test'} />);
    console.log(result);
  }
};
