import React, {useEffect, useState} from 'react';
import QuestionType from './QuestType';
import {question_type} from 'constants/common';
import {Button} from 'react-bootstrap';
import NextIcon from '../../assets/images/arrow-right.png';
import PrevIcon from '../../assets/images/arrow-left.png';
import Tooltip from 'components/common/tooltip';
import {useNavigate} from 'react-router-dom';
import BSLoader from 'components/common/loader';
import {formatPhoneNumber, isValidPhoneNumber} from 'utils/common';

const QuestionCard = ({
  questions,
  selectedQuestion,
  handleNext,
  handlePrevious,
  questionsAnswers,
  setQuestionsAnswers,
  setQuestionsOverview,
  stack = [],
  surveyLoading,
  lcmLoading,
  isSavingAns,
}) => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  useEffect(() => {
    if (questions?.length > 0) setQuestion(questions[selectedQuestion]);
  }, [questions, selectedQuestion]);

  const handleSubmit = e => {
    e.preventDefault();
  };

  const handleChange = (type, value, answer_text = '') => {
    if (
      type === question_type.multiple_choice ||
      type === question_type.dropdown ||
      type === question_type.input ||
      type === question_type.dynamic ||
      type === question_type.date
    ) {
      setQuestionsAnswers(pre =>
        pre.map((ele, index) => {
          if (index === selectedQuestion)
            return {
              ...ele,
              selected_answer_options: [
                {
                  ...(type !== question_type.input && type !== question_type.date && {answer_option_id: value}),
                  value: answer_text,
                },
              ],
            };
          return ele;
        }),
      );
    }
    else if (type === question_type.phone) {
      setDisableNext(!isValidPhoneNumber(answer_text.replace(/\D/g, '')));
      setInvalidPhone(!isValidPhoneNumber(answer_text.replace(/\D/g, '')));
      setQuestionsAnswers(pre =>
        pre.map((ele, index) => {
          if (index === selectedQuestion)
            return {
              ...ele,
              selected_answer_options: [
                {
                  value: formatPhoneNumber(answer_text.replace(/[^\d]/g, '')),
                },
              ],
            };
          return ele;
        }),
      );
    }
    if (type === question_type.multi_select) {
      setQuestionsAnswers(pre =>
        pre.map((ele, index) => {
          if (index === selectedQuestion)
            return {
              ...ele,
              selected_answer_options: [
                ...value.map(item => {
                  return {answer_option_id: item.value, value: item.label};
                }),
              ],
            };
          return ele;
        }),
      );
    }
  };
  return surveyLoading || lcmLoading ? (
    <div className="text-center py-5 bg-white w-100">
      <BSLoader className="my-5" />
    </div>
  ) : (
    <div className="question-card p-5">
      <div className="main-questioncard">
        <p className="heading-sm  text-secondary fw-bold  opacity-0 ">{`Question No. ${selectedQuestion + 1} of ${
          questions?.length
        }`}</p>
        <div className=" d-inline-block">
          <Tooltip title={'Exit the survey'}>
            <Button
              className="text-decoration-none text-dark fw-bold link-button"
              variant="link"
              onClick={() => navigate(-1)}
            >
              <img src={PrevIcon} alt="back arrow" className="link-arrow-left" /> Exit
            </Button>
          </Tooltip>
          <Tooltip title={'Go to questions overview'}>
            <Button
              className="text-decoration-none text-dark fw-bold link-button"
              variant="link"
              onClick={() => setQuestionsOverview(true)}
            >
              Overview <img src={NextIcon} alt="next arrow" className="link-arrow-right" />
            </Button>
          </Tooltip>
        </div>
      </div>

      <p className="heading-h5">{question?.question_text}</p>
      <form onSubmit={handleSubmit}>
        <QuestionType
          question={question}
          handleChange={handleChange}
          questionsAnswers={questionsAnswers}
          selectedQuestion={selectedQuestion}
        />
        {invalidPhone && <p className="text-danger">Phone Number must be 10 digits</p>}
        {selectedQuestion > 0 && stack.length > 1 && (
          <Button className="secondary-btn previous-btn" onClick={handlePrevious}>
            <img src={PrevIcon} alt="back arrow" className="pe-3" /> Previous
          </Button>
        )}
        <Button
          className="primary-btn next-btn"
          onClick={handleNext}
          disabled={
            !questionsAnswers[selectedQuestion]?.selected_answer_options?.length ||
            !questionsAnswers[selectedQuestion]?.selected_answer_options[0]?.value ||
            isSavingAns ||
            disableNext
          }
        >
          {isSavingAns ? (
            <span className="px-3">
              <BSLoader size="sm" />
            </span>
          ) : (
            <>
              {' '}
              Next
              <img src={NextIcon} alt="next arrow" className="ps-3" />
            </>
          )}
        </Button>
      </form>
    </div>
  );
};

export default QuestionCard;
