import {DASHBOARD_PATH} from 'constants/routePaths';
import {forEach, startCase} from 'lodash';
import React from 'react';
import {useLocation} from 'react-router-dom';
export const getBreadcrumbs = pathname => {
  const urlPath = pathname.split('/');
  const [, ...usablePath] = urlPath;

  let breadCrumbs = [
    {
      name: 'Dashboard',
      route: DASHBOARD_PATH,
    },
  ];
  let fullTrailPath = '/';

  forEach(usablePath, (path, i) => {
    fullTrailPath = fullTrailPath + `${path + (i !== usablePath.length - 1 ? '/' : '')}`;
    if (fullTrailPath !== '/dashboard')
      breadCrumbs.push({
        name: startCase(path),
        route: fullTrailPath,
      });
  });

  // In case of edit path remove the id before edit
  if (pathname.includes('edit')) {
    breadCrumbs.splice(breadCrumbs.length - 2, 1);
  }
  if (pathname.includes('provider-rate-history')) {
    breadCrumbs.splice(breadCrumbs.length - 2, 1);
  }

  return breadCrumbs;
};
export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};
export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};
export const getCreatedAt = () => {
  return localStorage.getItem('created_at');
};
export const getExpiresIn = () => {
  return localStorage.getItem('expires_in');
};
export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('uuInfo'));
};
export const setAccessToken = accessToken => {
  return localStorage.setItem('access_token', accessToken);
};
export const setRefreshToken = refreshToken => {
  return localStorage.setItem('refresh_token', refreshToken);
};
export const setUserInfoInStorage = (userInfo = {}) => {
  return localStorage.setItem('uuInfo', JSON.stringify(userInfo));
};
export const setTokenCreatedAt = createdAt => {
  return localStorage.setItem('created_at', createdAt);
};
export const setTokenExpiresIn = expiresIn => {
  return localStorage.setItem('expires_in', expiresIn);
};
export function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const checkUserLoggedInStatus = loginData => {
  if (loginData?.status_code === 200 || getAccessToken()) {
    return true;
  }

  return false;
};
export const removeAccessToken = () => {
  localStorage.removeItem('access_token');
};
export const removeRefreshToken = () => {
  localStorage.removeItem('refresh_token');
};
export const removeCreatedAt = () => {
  localStorage.removeItem('created_at');
};
export const removeExpiresIn = () => {
  localStorage.removeItem('expires_in');
};
export const removeUserInfoFromStorage = () => {
  localStorage.removeItem('uuInfo');
};

export function formatDate(inputDate) {
  // Check if inputDate is undefined, null, or an empty string
  if (!inputDate) {
    return '';
  }

  const dateObj = new Date(inputDate);

  // Check if the date object is valid
  if (isNaN(dateObj)) {
    return '';
  }

  const options = {year: 'numeric', month: 'short', day: 'numeric'};
  const formattedDate = dateObj.toLocaleDateString('en-US', options);
  return formattedDate;
}
export function highlightFirstMatch(string, subString) {
  if (!string) return '';
  if (!subString) return string;
  const startIndex = string?.toLowerCase().indexOf(subString.toLowerCase());

  if (startIndex === -1) {
    return string;
  }

  const matched = string.substr(startIndex, subString.length);
  const highlightedString = (
    <>
      {string.substring(0, startIndex)}
      <span className="highlight">{matched}</span>
      {string.substring(startIndex + subString.length)}
    </>
  );

  return highlightedString;
}
// export function formatDateTimeZone(inputDate) {
//   // Check for undefined, null, or empty string input
//   if (!inputDate) {
//     return '';
//   }

//   const dateObj = new Date(inputDate);

//   // Check if the date object is valid
//   if (isNaN(dateObj)) {
//     return '';
//   }

//   // Format date
//   const optionsDate = {year: 'numeric', month: 'short', day: 'numeric'};
//   const formattedDate = dateObj.toLocaleDateString('en-US', optionsDate);

//   // Format time
//   const optionsTime = {hour: 'numeric', minute: 'numeric', hour12: false};
//   const formattedTime = dateObj.toLocaleTimeString('en-US', optionsTime);

//   // Get time zone offset
//   const timeZoneOffset = -dateObj.getTimezoneOffset() / 60;
//   const timeZoneFormatted = `GMT${timeZoneOffset >= 0 ? '+' : '-'}${Math.abs(timeZoneOffset)}`;

//   return `${formattedDate}, ${formattedTime} ${timeZoneFormatted}`;
// }
export function formatDateTimeZone(inputDate) {
  // Check for undefined, null, or empty string input
  if (!inputDate) {
    return '';
  }

  const dateObj = new Date(inputDate);

  // Check if the date object is valid
  if (isNaN(dateObj)) {
    return '';
  }

  // Convert the input date to PST
  dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset());

  // Format date
  const optionsDate = {year: 'numeric', month: 'short', day: 'numeric'};
  const formattedDate = dateObj.toLocaleDateString('en-US', optionsDate);

  // Format time
  const optionsTime = {hour: '2-digit', minute: '2-digit', hour12: true};
  const formattedTime = dateObj.toLocaleTimeString('en-US', optionsTime);

  // Get time zone offset for PST
  const timeZoneFormatted = 'PST';

  return `${formattedDate}, ${formattedTime} ${timeZoneFormatted}`;
}

export function capitalizeName(name) {
  if (!name) {
    return ''; // Handle undefined, null, or empty string input
  }

  const words = name.trim().split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  const capitalizedFullName = capitalizedWords.join(' ');

  return capitalizedFullName;
}

export function capitalizePlace(name) {
  if (!name) {
    return ''; // Handle undefined, null, or empty string input
  }

  const words = name.trim().split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const capitalizedFullName = capitalizedWords.join(' ');

  return capitalizedFullName;
}
export const isSidebarVisible = route => {
  return !(route.includes('/survey/') || route.includes('/survey-history/'));
};

export const formatPhoneNumber = phoneNumber => {
  const cleanNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters

  const formattedNumber = `(${cleanNumber.slice(0, 3)}) ${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6)}`;
  return formattedNumber;
};

export const isValidPhoneNumber = phoneNumber => {
  const regex = /^(\d{3})[\s-]?(\d{3})[\s-]?(\d{4})$/; // US phone number pattern
  return regex.test(phoneNumber) && phoneNumber.length === 10;
};

export const convertToDDMMYYYY = inputDate => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Split the input date string into month, day, and year
  const [monthStr, dayStr, yearStr] = inputDate.split(' ');

  // Convert month string to numerical representation (0-indexed)
  const month = months.indexOf(monthStr);

  // Parse the day and year as integers
  const day = parseInt(dayStr, 10);
  const year = parseInt(yearStr, 10);

  // Create a new Date object
  const parsedDate = new Date(year, month, day);

  // Format the date as "dd-mm-yyyy"
  const dd = String(parsedDate.getDate()).padStart(2, '0');
  const mm = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const yyyy = parsedDate.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${dd}-${mm}-${yyyy}`;

  return formattedDate;
};

export function formatCurrentDate() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // Pad the day with leading zero if it's a single digit
  const formattedDay = day < 10 ? '0' + day : day;

  const formattedDate = `${month} ${formattedDay}, ${year}`;
  return formattedDate;
}

export const formatDateDiff = date => {
  if (date === null || date === undefined) return date;
  const [shortDate, time, md] = date.split(' ');
  let pstShortDate = new Date().toLocaleString('en-US', {timeZone: 'PST'}).split(' ')[0];

  const timeDiff = new Date(pstShortDate).getTime() - new Date(shortDate).getTime();
  const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));

  if (dayDiff === 0) {
    return `Today at ${time} ${md}`;
  } else if (dayDiff === 1) {
    return `Yesterday at ${time} ${md}`;
  } else if (dayDiff < 7) {
    return 'This week';
  } else if (dayDiff >= 7 && dayDiff < 14) {
    return 'Last week';
  } else {
    return date;
  }
};

export function truncateString(str, len = 10) {
  if (str.length > len) {
    return str.slice(0, len) + '...';
  } else {
    return str;
  }
}

export function diffHours(dt2, dt1) {
  // Calculate the difference in milliseconds between the two provided Date objects by subtracting the milliseconds value of dt1 from the milliseconds value of dt2
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  // Convert the difference from milliseconds to hours by dividing it by the number of seconds in an hour (3600)
  diff /= 60 * 60;
  // Return the absolute value of the rounded difference in hours
  return Math.abs(Math.round(diff));
}
