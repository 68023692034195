import * as yup from 'yup';
export const memberSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[A-Za-z\s'-]{1,50}$/, 'First name should only contain letters')
    .required('Required *'),
  last_name: yup
    .string()
    .matches(/^[A-Za-z\s'-]{1,50}$/, 'Last name should only contain letters')
    .required('Required *'),
  date_of_birth: yup.string().required('Required *'),
  city: yup.string().required('Required *'),
  cin: yup.string().required('Required *'),
  mif: yup.string().required('Required *'),
  account_number: yup.string().required('Required *'),
  mif_status: yup.string().required('Required *'),
  enrollment_status: yup.string().required('Required *'),
});

export const memberInitialValues = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  city: '',
  cin: '',
  mif: '',
  account_number: '',
  mif_status: '',
  enrollment_status: '',
};
