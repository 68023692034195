import React, {useState} from 'react';
import Datetime from 'react-datetime';
import {CalendarBlank} from 'phosphor-react';
import 'react-datetime/css/react-datetime.css';
import './index.scss';

function DateInput({className, handleChange, name, value, placeholder, label, isError, error, ...rest}) {
  const [isActive, setActive] = useState(false);
  const handleDateChange = date => {
    handleChange(date.format('MMMM DD, YYYY'));
  };
  const renderInput = ({name, value, placeholder, isError, isActive}, openCalendar, closeCalendar) => {
    return (
      <div className="position-relative w-100 ">
        <input
          type={'text'}
          className={`theme-input input w-100 z-1 ${isError ? 'border-danger' : ''} ${isActive ? 'border-active' : ''}`}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled
          {...rest}
        />
        <span
          className="position-absolute top-50 end-0 translate-middle p-1 pe-3 z-3 cursor-pointer"
          onClick={openCalendar}
        >
          <CalendarBlank size={30} fontWeight={800} color="#60606090" />
        </span>
      </div>
    );
  };

  return (
    <div className={` ${className ? className : ''} input-otr`}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <Datetime
        initialViewMode="days"
        renderInput={renderInput}
        dateFormat="MMMM DD,YYYY"
        timeFormat={false}
        closeOnSelect
        placeholder={placeholder}
        label={'Date'}
        inputProps={{
          isError,
          name,
          value,
          placeholder,
          isActive,
        }}
        onChange={handleDateChange}
        onOpen={() => setActive(true)}
        onClose={() => setActive(false)}
        closeOnClickOutside={true}
      />
      {error ? error : null}
    </div>
  );
}

export default DateInput;
