import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import AsyncSelect from 'components/common/select/AsyncSelect';
import {policyInitialValues, policySchema} from 'constants/policies';
import {getOutreachStaffs} from 'containers/outreachstaff/api';
import {getCities, getCounties, getHealthPlans, getStates} from 'containers/policies/api';
import {ErrorMessage, Formik} from 'formik';
import {DEBOUNCE_DELAY, initialMetaForTable} from 'constants/common';
import debounce from 'lodash/debounce';
import {useEffect, useCallback, useState} from 'react';
const alphabetArray = [];

for (let charCode = 65; charCode <= 90; charCode++) {
  const char = String.fromCharCode(charCode);
  alphabetArray.push({value: char, label: char});
}
const PoliciesModal = ({
  handlePolicySubmission,
  handleClose,
  healthPlansList,
  stateList,
  outreachStaffList,
  currentPolicyEdit,
}) => {
  const [countiesList, setCountiesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateId, setStateId] = useState(typeof currentPolicyEdit === 'object' ? currentPolicyEdit.state_id : '');
  const [countyId, setCountyId] = useState(typeof currentPolicyEdit === 'object' ? currentPolicyEdit.county_id : '');
  const [isCountyLoading, setIsCountyLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false);

  const fetchCounties = useCallback(
    async searchString => {
      setIsCountyLoading(true);
      const result = await getCounties({...initialMetaForTable, search: searchString, state_id: stateId});

      if (result['counties']) {
        const data = result?.counties;
        setCountiesList(data);
        setIsCountyLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [stateId],
  );

  const fetchCities = useCallback(
    async searchString => {
      setIsCityLoading(true);
      const result = await getCities({
        ...initialMetaForTable,
        search: searchString,
        state_id: stateId,
        county_id: countyId,
      });

      if (result['cities']) {
        const data = result?.cities;
        setCitiesList(data);
        setIsCityLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [countyId, stateId],
  );

  const debouncedFetchCounties = useCallback(
    debounce(searchString => {
      fetchCounties(searchString);
    }, DEBOUNCE_DELAY),
    [stateId],
  );
  const debouncedFetchCities = useCallback(
    debounce(searchString => {
      fetchCities(searchString);
    }, DEBOUNCE_DELAY),
    [countyId],
  );

  useEffect(() => {
    debouncedFetchCounties();
  }, [debouncedFetchCounties]);
  useEffect(() => {
    debouncedFetchCities();
  }, [debouncedFetchCities]);
  return (
    <Formik
      initialValues={typeof currentPolicyEdit === 'object' ? {...currentPolicyEdit} : policyInitialValues}
      validationSchema={policySchema}
      onSubmit={(values, {setSubmitting}) => {
        handlePolicySubmission(values, setSubmitting);
      }}
    >
      {({handleChange, values, handleSubmit, setFieldValue, errors, isSubmitting}) => {
        return (
          <div className="question-modal">
            <form className="form-main" onSubmit={handleSubmit}>
              <hr className="custom-modal-line mt-0 " />
              <div>
                <Input
                  handleChange={handleChange}
                  placeholder="e.g. Policy A12"
                  type="text"
                  label="Title"
                  value={values.name}
                  name="name"
                  error={<ErrorMessage className="error-message" component="p" name="name" />}
                />
              </div>

              <div>
                <AsyncSelect
                  label="Health Plan"
                  options={
                    healthPlansList.find(el => el.id === currentPolicyEdit.health_plan_id) ||
                    typeof currentPolicyEdit.health_plan_id == 'undefined'
                      ? healthPlansList?.map(el => {
                          return {value: el.id, label: el.name};
                        })
                      : [
                          ...healthPlansList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                          {
                            value: currentPolicyEdit.health_plan?.id,
                            label: currentPolicyEdit.health_plan?.name,
                          },
                        ]
                  }
                  dataCallback={async inputValue => {
                    const response = await getHealthPlans({search: inputValue});
                    const formatedOptions = response?.health_plans?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    return formatedOptions;
                  }}
                  selectedValue={values.health_plan_id}
                  placeholder="Select Health Plan "
                  handleChange={obj => {
                    setFieldValue('health_plan_id', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="health_plan_id" />}
                />
              </div>
              <div>
                <AsyncSelect
                  label="State"
                  options={
                    stateList.find(plan => plan.id === currentPolicyEdit.state_id) ||
                    typeof currentPolicyEdit.state_id == 'undefined'
                      ? [
                          {value: null, label: '--No Select--'},
                          ...stateList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                        ]
                      : [
                          {value: null, label: '--No Select--'},
                          ...stateList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                          {
                            value: currentPolicyEdit.state?.id,
                            label: currentPolicyEdit.state?.name,
                          },
                        ]
                  }
                  dataCallback={async inputValue => {
                    const response = await getStates({search: inputValue});
                    let formatedOptions = response?.states?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    formatedOptions = [{value: null, label: '--No Select--'}, ...formatedOptions];
                    return formatedOptions;
                  }}
                  selectedValue={values.state_id}
                  placeholder="Select State "
                  handleChange={obj => {
                    setStateId(obj.value);
                    setFieldValue('state_id', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="state_id" />}
                />
              </div>
              <div>
                <AsyncSelect
                  label="County"
                  disabled={values.state_id ? false : true}
                  isLoading={isCountyLoading}
                  options={
                    countiesList.find(plan => plan.id === currentPolicyEdit.county_id) ||
                    typeof currentPolicyEdit.county_id == 'undefined'
                      ? [
                          {value: null, label: '--No Select--'},
                          ...countiesList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                        ]
                      : [
                          {value: null, label: '--No Select--'},
                          ...countiesList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                          {
                            value: currentPolicyEdit.county?.id,
                            label: currentPolicyEdit.county?.name,
                          },
                        ]
                  }
                  dataCallback={async inputValue => {
                    const response = await getCounties({search: inputValue, state_id: values.state_id});
                    let formatedOptions = response?.counties?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    formatedOptions = [{value: null, label: '--No Select--'}, ...formatedOptions];
                    return formatedOptions;
                  }}
                  selectedValue={values.county_id}
                  placeholder="Select County "
                  handleChange={obj => {
                    setCountyId(obj.value);
                    setFieldValue('county_id', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="county_id" />}
                />
              </div>
              <div>
                <AsyncSelect
                  label="City"
                  isMulti={true}
                  disabled={values.county_id ? false : true}
                  isLoading={isCityLoading}
                  options={citiesList.map(el => ({value: el.id, label: el.name}))}
                  dataCallback={async inputValue => {
                    const response = await getCities({
                      search: inputValue,
                      state_id: values.state_id,
                      county_id: values.county_id,
                    });
                    let formatedOptions = response?.cities?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    return formatedOptions;
                  }}
                  selectedValue={values?.cities}
                  placeholder="Select City"
                  handleChange={arr => {
                    setFieldValue('cities', arr);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="city_id" />}
                />
              </div>
              <div className="input-flex row">
                <div className="col-sm-6">
                  <SelectComponent
                    label="Starting Alphabet"
                    options={alphabetArray}
                    selectedValue={values.char_start_filter}
                    placeholder="Select Starting Alphabet "
                    handleChange={obj => {
                      setFieldValue('char_start_filter', obj.value);
                      // setFieldValue('char_end_filter', '');
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="char_start_filter" />}
                  />
                </div>
                <div className="col-sm-6">
                  <SelectComponent
                    label="Ending Alphabet"
                    options={alphabetArray.map(option => {
                      if (option.value > values.char_start_filter) {
                        return option;
                      } else {
                        return {...option, isDisabled: true};
                      }
                    })}
                    selectedValue={values.char_end_filter}
                    placeholder="Select Ending Alphabet "
                    handleChange={obj => {
                      setFieldValue('char_end_filter', obj.value);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="char_end_filter" />}
                  />
                </div>
              </div>
              <div>
                <AsyncSelect
                  label="Outreach Staff"
                  options={
                    outreachStaffList.find(plan => plan.id === currentPolicyEdit.outreach_staff_id) ||
                    typeof currentPolicyEdit.outreach_staff_id == 'undefined'
                      ? outreachStaffList?.map(el => {
                          return {value: el.id, label: el.name};
                        })
                      : [
                          ...outreachStaffList?.map(el => {
                            return {value: el.id, label: el.name};
                          }),
                          {
                            value: currentPolicyEdit.outreach_staff?.id,
                            label: currentPolicyEdit.outreach_staff?.name,
                          },
                        ]
                  }
                  dataCallback={async inputValue => {
                    const response = await getOutreachStaffs({search: inputValue, status: true});
                    const formatedOptions = response?.outreach_staffs?.map(el => ({
                      value: el.id,
                      label: el.name,
                    }));
                    return formatedOptions;
                  }}
                  selectedValue={values.outreach_staff_id}
                  placeholder="Select Outreach Staff "
                  handleChange={obj => {
                    setFieldValue('outreach_staff_id', obj.value);
                  }}
                  error={<ErrorMessage className="error-message" component="p" name="outreach_staff_id" />}
                />
              </div>
              <hr className="custom-modal-line mt-4 " />
              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                {isSubmitting ? (
                  <>
                    <span className="primary-btn w-50">
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Submitting...
                    </span>
                  </>
                ) : (
                  <input
                    className="primary-btn w-50"
                    type="submit"
                    name="submit"
                    value={typeof currentPolicyEdit === 'object' ? 'Save Changes' : 'Add Policy'}
                  />
                )}
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default PoliciesModal;
