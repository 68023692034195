import {ReactComponent as MiniLogo} from 'assets/images/logo.svg';
import {Anchor, CalendarBlank, Hash, SignOut, User} from 'phosphor-react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './surveyHeader.scss';
import {HeaderCard} from './HeaderCard';

const SurveyHeeader = ({children}) => {
  const rightMenu = React.Children.toArray(children).some(child => child.type === SurveyHeeader.RightMenu);
  return <header className={`justify-content-between align-items-center`}>{children}</header>;
};

const Applogo = () => {
  const navigate = useNavigate();

  return <MiniLogo className="cursor-pointer" onClick={() => navigate('/members')} />;
};
SurveyHeeader.Logo = () => {
  return <Applogo />;
};
const RightMenu = ({logoutHandler}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdownHandler = () => {
    setShowDropdown(pre => !pre);
  };
  const member = JSON.parse(localStorage.getItem('member'));
  const survey = JSON.parse(localStorage.getItem('survey'));
  return (
    <>
      <div className="mt-1 d-flex right-menu gap-2">
        <HeaderCard icon={<Hash size={8} />} title="MIF Auth" subTitle={member?.ecm_authorization_number} />
        <HeaderCard icon={<Hash size={8} />} title="Survey Started" subTitle={survey?.created_at} />
        <HeaderCard icon={<Hash size={8} />} title="Account Number" subTitle={member?.account_number} />
        <HeaderCard icon={<Hash size={8} />} title="Medical Record Number" subTitle={member?.medical_record_number} />
        <HeaderCard icon={<User size={8} />} title="Patient Name" subTitle={member?.name} />
        <HeaderCard icon={<CalendarBlank size={8} />} title="Date of Birth" subTitle={member?.date_of_birth} />
        <HeaderCard icon={<Anchor size={8} />} title="Insurance" subTitle={member.health_plan_name} />
        <HeaderCard icon={<Hash size={8} />} title="Subscriber ID" subTitle={member?.id} />
      </div>
      <div className="right-dropdown">
        <User size={30} onClick={toggleDropdownHandler} />
        <div className={showDropdown ? 'survey-dropdown' : 'd-none'}>
          <HeaderCard icon={<Hash size={12} />} title="MIF Auth" subTitle={member?.ecm_authorization_number} />
          <HeaderCard icon={<Hash size={12} />} title="Survey Started" subTitle={survey?.created_at} />
          <HeaderCard icon={<Hash size={12} />} title="Account Number" subTitle={member?.account_number} />
          <HeaderCard icon={<Hash size={8} />} title="Medical Record Number" subTitle={member?.medical_record_number} />
          <HeaderCard icon={<User size={12} />} title="Patient Name" subTitle={member?.name} />
          <HeaderCard icon={<CalendarBlank size={12} />} title="Date of Birth" subTitle={member?.date_of_birth} />
          <HeaderCard icon={<Anchor size={12} />} title="Insurance" subTitle={member.health_plan_name} />
          <HeaderCard icon={<Hash size={12} />} title="Subscriber ID" subTitle={member?.id} />
          <div onClick={logoutHandler}>
            <SignOut size={24} className="ms-1 opacity-50" />
            <span className="ms-3 opacity-50">Logout</span>
          </div>
        </div>
      </div>
    </>
  );
};
SurveyHeeader.RightMenu = RightMenu;

export {SurveyHeeader};
