import Loader from 'components/common/loader';
import CustomModal from 'components/common/modal';
import {HeaderCard} from 'components/layout/surveyHeader/HeaderCard';
import {getMember} from 'containers/members/api';
import {
  createSurveyComment,
  discardSurvey,
  getSurveyComments,
  getSurveyHistory,
  updateSurveyComment,
} from 'containers/survey/api';
import {Anchor, ArrowLeft, CalendarBlank, Hash, MapPinLine, Minus, Pencil, Plus, Trash, User} from 'phosphor-react';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {capitalizeName} from 'utils/common';
import './survey.scss';

const memberInfoTransform = (member = {}) => {
  let full_address = [member.city?.name || '', member.state?.name || '', member.zip_code?.code || ''];
  full_address = full_address.filter(segment => !!segment).join(full_address.length > 1 ? ', ' : '');

  return {
    memberID: member.id,
    memberAccountNo: member.account_number,
    memberName: member.name,
    memberPreferredLanguageSpoken: member.member_preferred_language_spoken || 'N/A',
    medicalRecordNumber: member.medical_record_number || 'N/A',
    cin: member.cin || '',
    enrollment_status: member.enrollment_status || '',
    mif: member.mif || '',
    mif_status: member.mif_status || '',
    memberDOB: member.date_of_birth,
    city: member.city || '',
    created_at: member.created_at || '',
    updated_at: member.updated_at || '',
    checked: member.checked || '',
    zip_code: member?.zip_code?.code || '',
    county: member.county?.name ? `(${member.county.name} County)` : '',
    state: member.state,
    outreach_staff: member?.outreach_staff?.name || 'N/A',
    health_plan: member.health_plan?.name || 'N/A',
    conditions: member.conditions,
    population_focus: member.population_focus,
    street: member.street || '',
    full_address,
  };
};
const SurveyHistory = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const member = JSON.parse(localStorage.getItem('member'));
  const [memberInfo, setMemberInfo] = useState(memberInfoTransform(member));

  const [showDetailsId, setshowDetailsId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [surveyList, setSurveyList] = useState([]);
  const [showMemberInfo, setShowMemberInfo] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selected, setSelected] = useState('');
  const [comment, setComment] = useState('');

  const handleCommentChange = event => {
    setComment(prev => ({...prev, content: event.target.value}));
  };

  const handleCommentUpdationOrCreation = async event => {
    let result;
    let content = event.target.value;

    if (!comment?.id && !!memberInfo) {
      result = await createSurveyComment(memberInfo?.memberID, content);
    } else {
      result = await updateSurveyComment(comment?.id, content);
    }

    if (result?.comment) {
      setComment(result?.comment);
    }
  };

  const fetchSurveyComment = async memberId => {
    const result = await getSurveyComments(memberId);
    if (result?.comments) {
      setComment(result?.comments[0]);
    }
  };

  const fetchSurveyHistory = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getSurveyHistory(id);
      if (result['survey_responses']) {
        const data = result?.survey_responses;
        setSurveyList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const fetchMember = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getMember(id);

      if (result['member']) {
        const {member} = result;
        setMemberInfo(memberInfoTransform(member));
        fetchSurveyComment(member.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    fetchSurveyHistory();
    fetchMember();
  }, [refreshPage]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOpenDeleteModal = id => {
    setSelected(id);
    setShowDeleteConfirm(true);
  };

  const handleCloseDeleteModal = () => {
    setSelected('');
    setShowDeleteConfirm(false);
  };

  const toggleMemberInfo = () => {
    if (screenWidth <= 600) {
      setShowMemberInfo(prevState => !prevState);
    }
  };

  const handleDiscard = async surveyId => {
    try {
      await discardSurvey(surveyId);
      setSurveyList(prevList => prevList.filter(survey => survey.id !== surveyId));
      const updatedSurveyList = surveyList.filter(survey => survey.id !== surveyId);
      localStorage.setItem('surveyList', JSON.stringify(updatedSurveyList));
    } catch (error) {
      console.error('Error discarding survey:', error);
    }
  };

  const handleContinue = surveyId => {
    const nextUnattemptedIndex = surveyList.findIndex(survey => survey.id === surveyId);

    if (nextUnattemptedIndex !== -1) {
      navigate(`/survey/${memberInfo.memberID}`);
    } else {
      navigate('/');
    }
  };

  const hadleDeleteConfirm = () => {
    handleDiscard(selected);
    handleCloseDeleteModal();
  };

  return (
    <>
      <div className="row ms-4">
        <div className="col">
          {loading ? (
            <div className="loader-wrapper">
              <Loader />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-12 col-sm-9 pe-sm-5">
                  <div className="main-header pb-5">
                    <div className="arrow-and-content">
                      <ArrowLeft
                        className="opacity-50 mb-2 me-2 cursor-pointer"
                        size={24}
                        onClick={() => navigate(-1)}
                      />
                      <h3 className="space">{capitalizeName(memberInfo.memberName)}'s Survey History</h3>
                    </div>
                    {/* <button className="survey-btn secondary-btn">Exclusion Survey</button> */}
                    <button
                      className={`survey-btn secondary-btn cursor-pointer `}
                      onClick={() => {
                        navigate(`/survey/${memberInfo.memberID}`, {state: member});
                      }}
                    >
                      Start Survey
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-container">
                <div className="main-container d-flex w-100">
                  <div className="main-card">
                    {surveyList?.map((survey, surveyIndex, arr) => {
                      const showQuestions = surveyIndex === showDetailsId;
                      const isPendingOrInProgress = survey.status === 'pending' || survey.status === 'in_progress';
                      return (
                        <div className={`card ${surveyIndex !== 0 ? 'mt-4' : ''}`} key={surveyIndex}>
                          <div className="card-header">
                            <div className="survey-history p-3">
                              <h3>Survey No.{arr.length - surveyIndex}</h3>
                              <div className="main-btn">
                                {isPendingOrInProgress && (
                                  <>
                                    <Trash
                                      size={24}
                                      className="cursor-pointer me-2 mb-3"
                                      onClick={() => handleOpenDeleteModal(survey.id)}
                                    />
                                    <Pencil
                                      size={24}
                                      className="cursor-pointer me-2 mb-3"
                                      onClick={() => handleContinue(survey.id)}
                                    />
                                  </>
                                )}
                                <p
                                  className={`status ${
                                    survey.status === 'completed'
                                      ? 'Enrolled'
                                      : survey.status === 'in_progress'
                                      ? 'Excluded'
                                      : 'pending'
                                  } ps-1 pe-1`}
                                >
                                  {survey.status === 'completed'
                                    ? 'Completed'
                                    : survey.status === 'in_progress'
                                    ? 'In Progress'
                                    : 'In Progress'}
                                </p>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="card-body">
                            <div className="mt-1 body-contents">
                              <div className="ms-3 d-flex header-card">
                                <HeaderCard
                                  icon={<CalendarBlank size={16} />}
                                  title="Survey submission date"
                                  subTitle={survey.updated_at}
                                />
                              </div>
                              <div className="ms-5 d-flex header-card">
                                <HeaderCard
                                  icon={<User size={16} />}
                                  title="Survey submited by"
                                  subTitle={capitalizeName(survey?.submitted_by_jsonb?.name) || 'N/A'}
                                />
                              </div>
                            </div>
                            <p className="border-top mt-4"></p>
                          </div>
                          <div className={`card-footer ${showQuestions ? 'active' : ''}`}>
                            <div
                              onClick={() => {
                                if (showQuestions) {
                                  setshowDetailsId('');
                                } else {
                                  setshowDetailsId(surveyIndex);
                                }
                              }}
                              className="cursor-pointer"
                            >
                              {showQuestions &&
                                survey?.answers_jsonb
                                  ?.filter(ans => ans.selected_answer_options?.length)
                                  .map((ans, index, arr) => (
                                    <div className="d-flex flex-column ps-4 pt-3 m-3 expand-card">
                                      <p className="opacity-50">{`Question No.${index + 1} of ${arr.length}`}</p>
                                      <h6>{ans?.question_text}</h6>
                                      {ans?.selected_answer_options?.map(option => (
                                        <p className="opacity-50">{option?.value}</p>
                                      ))}
                                    </div>
                                  ))}
                              {showQuestions && <hr />}
                              <div
                                className={`d-flex justify-content-between align-items-center ms-3 me-3 ${
                                  showQuestions ? 'active' : ''
                                }`}
                              >
                                {showQuestions ? (
                                  <>
                                    <p className="text">{'Show fewer details'}</p>
                                    <div className="cursor-pointer">
                                      <Minus size={20} />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p className="text">{'Show more details'}</p>
                                    <div className="cursor-pointer">
                                      <Plus size={20} />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="survey-info">
                    <h5 className="text-nowrap">Assigned Outreach Staff </h5>
                    <div className="d-flex align-items-center header-card survey-space">
                      <HeaderCard icon={<User size={16} />} title="" subTitle={memberInfo.outreach_staff} />
                    </div>
                    <div className="line"></div>
                    <h5 className="text-nowrap" onClick={toggleMemberInfo}>
                      Member Info
                      {screenWidth <= 600 ? showMemberInfo ? <Minus size={20} /> : <Plus size={20} /> : null}
                    </h5>
                    {(screenWidth <= 600 && showMemberInfo) || screenWidth > 600 ? (
                      <>
                        <div className="mt-1 d-flex flex-column justify-content-between survey-space">
                          <HeaderCard
                            icon={<Hash size={16} />}
                            title="Account Number"
                            subTitle={memberInfo.memberAccountNo}
                          />
                          <HeaderCard icon={<Hash size={16} />} title="CIN" subTitle={memberInfo.cin} />
                          <HeaderCard icon={<Hash size={16} />} title="MRN" subTitle={memberInfo.medicalRecordNumber} />
                          <HeaderCard icon={<Hash size={16} />} title="Member Preferred Language Spoken" subTitle={memberInfo.memberPreferredLanguageSpoken} />
                          <HeaderCard
                            icon={<Hash size={16} />}
                            title="MIF Status"
                            subTitle={
                              memberInfo.mif_status ? (
                                <span
                                  className={`status ${memberInfo.mif_status === 'active' ? 'active' : 'inactive'}`}
                                >
                                  {`${memberInfo.mif_status.charAt(0).toUpperCase()}${memberInfo.mif_status.slice(1)}`}
                                </span>
                              ) : (
                                'N/A'
                              )
                            }
                          />
                          <HeaderCard
                            icon={<User size={16} />}
                            title="Patient Name"
                            subTitle={capitalizeName(memberInfo.memberName)}
                          />
                          <HeaderCard
                            icon={<CalendarBlank size={16} />}
                            title="Date of Birth"
                            subTitle={memberInfo.memberDOB}
                          />
                          <HeaderCard
                            icon={<MapPinLine size={16} />}
                            title={'Address ' + memberInfo.county}
                            subTitle={
                              <>
                                {memberInfo.street && <div>{memberInfo.street}</div>}
                                {memberInfo.full_address && <span> {memberInfo.full_address}</span>}
                              </>
                            }
                          />
                          <HeaderCard icon={<Anchor size={16} />} title="Insurance" subTitle={memberInfo.health_plan} />
                          <HeaderCard icon={<Hash size={16} />} title="Subscriber ID" subTitle={memberInfo.memberID} />
                        </div>
                        <h5 className="mt-3">Population OF Focus</h5>
                        <div className="d-flex flex-row gap-3 btn-1 survey-btn">
                          {member.population_focus && member.population_focus.trim() !== ''
                            ? member.population_focus.split(',').map((item, index) => <p key={index}>{item.trim()}</p>)
                            : ''}
                        </div>
                        <h5 className="mt-3">Conditions</h5>
                        <div className="d-flex flex-column gap-3 btn-2 survey-btn">
                          <div className="d-flex flex-row gap-3">
                            {member.conditions && member.conditions.trim() !== ''
                              ? member.conditions.split(',').map((item, index) => <p key={index}>{item.trim()}</p>)
                              : ''}
                          </div>
                        </div>
                        <h5 className="mt-3">Notes</h5>
                        <textarea
                          value={comment?.content}
                          onChange={handleCommentChange}
                          onBlur={handleCommentUpdationOrCreation}
                          className="notes-text-area"
                          rows={4}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showDeleteConfirm && (
        <CustomModal size="sm" show onHide={handleCloseDeleteModal} heading={`Confirmation`}>
          <div className="question-modal">
            <hr className="custom-modal-line mt-0 " />

            <form className="form-main" onSubmit={() => {}}>
              <h6>Are you sure you want to delete this survey?</h6>

              <hr className="custom-modal-line mt-4" />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleCloseDeleteModal}>
                  Cancel
                </button>
                <button className="primary-btn w-50" type="button" onClick={hadleDeleteConfirm}>
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default SurveyHistory;
