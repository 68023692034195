export const LOGIN_PATH = '/';
export const RESET_PASSWORD_PATH = '/reset-password';
export const DASHBOARD_PATH = '/dashboard';
export const QUESTIONS_PATH = '/questions';
export const Leadcaremanager_PATH = '/LCM-Supervisors';
export const OutreachLeaders_PATH = '/outreachLeaders';
export const OutreachStaff_PATH = '/outreachStaff';
export const Members_PATH = '/members';
export const POLICIES_PATH = '/policies';
export const SURVEY_HISTORIES_PATH = '/survey-history';
export const SURVEY_HISTORY_PATH = '/survey-history/:id';
export const SURVEY_PATH = '/survey/:id';
export const RELEASE_PATH = '/releases'
export const RELEASE_DETAIL_PATH= '/releases/:id'
