import {ReactComponent as AlertPrimaryImg} from 'assets/images/alert-primary.svg';
import {ReactComponent as AlertSecondaryImg} from 'assets/images/alert-secondary.svg';
import {ReactComponent as AlertInfoImg} from 'assets/images/alert-info.svg';
import {ReactComponent as AlertSuccessImg} from 'assets/images/alert-success.svg';
import {ReactComponent as AlertDangerImg} from 'assets/images/alert-danger.svg';
import {ReactComponent as AlertWarningImg} from 'assets/images/alert-warning.svg';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GRANT_TYPE = Object.freeze({
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
})
export const initialMetaForTable = {
  page: 1,
  perPage: 10,
  search: '',
};

export const DEBOUNCE_DELAY = 700;

export const toastContainerProps = {
  hideProgressBar: true,
  closeButton: false,
  autoClose: 5000,
  limit: 2,
  position: 'bottom-right',
  closeOnClick: false,
};

export const alertTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const alertIcons = {
  [alertTypes.INFO]: <AlertInfoImg />,
  [alertTypes.DANGER]: <AlertDangerImg />,
  [alertTypes.WARNING]: <AlertWarningImg />,
  [alertTypes.SUCCESS]: <AlertSuccessImg />,
  [alertTypes.PRIMARY]: <AlertPrimaryImg />,
  [alertTypes.SECONDARY]: <AlertSecondaryImg />,
};
export const question_type = {
  dropdown: 'dropdown',
  multiple_choice: 'multiple_choice',
  input: 'text',
  checkbox: 'checkbox',
  text: 'text',
  multi_select: 'multi_select',
  dynamic: 'dynamic',
  date: 'date',
  phone: 'phone',
};

export const LOGIN_ROLES = {
  outreachleader: 'OutreachLeader',
  outreachstaff: 'OutreachStaff',
};
