import ForgotForm from 'components/login/ForgotForm';
import LoginForm from 'components/login/LoginForm';
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {loginAction} from 'store/action-creators/login';
import {getAccessToken, getUserInfo, setAccessToken, setRefreshToken, setTokenCreatedAt, setTokenExpiresIn, GRANT_TYPE, setUserInfoInStorage, useQuery} from 'utils/common';
import './login.scss';
import {LOGIN_ROLES} from 'constants/common';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const shouldResetPassword = query.get('reset');
  const [checked, setCheck] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      if (getUserInfo()?.type === LOGIN_ROLES.outreachstaff) {
        navigate('/members');
      } else navigate('/dashboard');
    }
  }, [navigate]);
  const handleCheckChange = () => {
    setCheck(!checked);
  };
  const loginFormSubmitHandler = async (values, setErrors, setSubmitting) => {
    const response = await dispatch(loginAction(values, setSubmitting));
    if (response?.status_code === 200) {
      setAccessToken(response.access_token);
      setRefreshToken(response.refresh_token);
      setTokenCreatedAt(response.created_at);
      setTokenExpiresIn(response.expires_in);
      setUserInfoInStorage(response.result);
      if (LOGIN_ROLES[response?.result?.type?.toLowerCase()] === LOGIN_ROLES.outreachstaff) {
        navigate('/members');
      } else {
        navigate('/dashboard');
      }
    }
  };
  return (
    <div className="Login-main">
      <div className="logo mt-4">
        <span className="heading-h6 title text-uppercase">Titanium</span>
        <span className="heading-xsb text-uppercase sub-title">Healthcare</span>
      </div>
      <div className="col-login-otr">
        <div className="col-login-inr">
          {shouldResetPassword ? (
            <ForgotForm />
          ) : (
            <LoginForm
              checked={checked}
              handleCheckChange={handleCheckChange}
              formSubmitHandler={loginFormSubmitHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
