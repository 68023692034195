import React from 'react';
import Outreachstaff from 'components/outreachstaff/Outreachstaff';

const OutreachStaff= () => {
  return <>
  <div className='main-contents'>
    <h1>Outreach Staff</h1>
    <Outreachstaff/>
  </div>
  </>
};


export default OutreachStaff;