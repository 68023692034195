import React from 'react';
import Outreachleader from 'components/outreachleader/Outreachleader';
const OutreachLeaders= () =>{
  return <>
  <div className='main-contents'>
    <h1>Outreach Leaders</h1>
    <Outreachleader/>
  </div>
  </>
};

export default OutreachLeaders;