import CustomModal from 'components/common/modal';
import Tooltip from 'components/common/tooltip';
import {getCurrentRelease} from 'containers/releases/api';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLatestRelease} from 'store/reducers/latestRelease';
import CurrentRelease from './CurrentReleaseModal';

function LatestRelease({minified, withToolTip = true}) {
  const {latestRelease} = useSelector(state => {
    return state.release;
  });
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRelease, setCurrentRelease] = useState(null);

  const handleReleaseModal = () => {
    setIsModalVisible(prev => !prev);
  };

  const getReleaseVersion = release => {
    release?.id &&
      getCurrentRelease(release.id).then(result => {
        setCurrentRelease(result?.release);
      });
  };

  useEffect(() => {
    dispatch(fetchLatestRelease());
  }, []);

  useEffect(() => {
    setCurrentRelease(latestRelease);
  }, [latestRelease]);

  const renderLatestRelease = () =>
    latestRelease ? (
      <div onClick={handleReleaseModal} className="fw-bold" role="button">
        {minified ? (
          <span>V{latestRelease.version}</span>
        ) : (
          <span>
            V{latestRelease.version} {`(${latestRelease.release_type})`}
          </span>
        )}
      </div>
    ) : (
      <></>
    );
  return (
    <>
      {withToolTip ? (
        <Tooltip
          id="release"
          placement="top"
          title={
            <div className="d-grid gap-1">
              <div>Release Notes - {latestRelease?.created_at}</div>
              <div className="text-start">
                {!!latestRelease?.features_list.length && (
                  <>
                    <label>Features:</label>
                    <ul>
                      {latestRelease?.features_list?.map(feat => (
                        <li key={feat}>{feat}</li>
                      ))}
                    </ul>
                  </>
                )}
                {!!latestRelease?.bugs_list?.length && (
                  <>
                    <label>Bugs:</label>
                    <ul>
                      {latestRelease?.bugs_list?.map(bug => (
                        <li key={bug}>{bug}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          }
        >
          {renderLatestRelease()}
        </Tooltip>
      ) : (
        renderLatestRelease()
      )}

      {isModalVisible && (
        <CustomModal size="sm" show onHide={handleReleaseModal} heading="Releases">
          <CurrentRelease
            handleClose={handleReleaseModal}
            currentRelease={{...currentRelease, label: currentRelease?.version, value: currentRelease?.id}}
            onVersionChange={getReleaseVersion}
          />
        </CustomModal>
      )}
    </>
  );
}

export default LatestRelease;
