import React from 'react';
import Dashboardcontent from 'components/dashboard/Dashboardcontent';
import {CaretDown} from 'phosphor-react';
const Dashboard = () => {
  return (
    <>
      <div className="main-contents">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h1>Dashboard</h1>
          {/* <div className="dropdown me-3">
            <span className="text-nowrap fw-bold d-none">Last 7 Days</span> 
            <CaretDown size={24} />
          </div> */}
        </div>
        <Dashboardcontent />
      </div>
    </>
  );
};

export default Dashboard;
